export const SIGN_IN = '/signin';
export const SIGN_UP = '/user/signup';
export const OTP_VERIFICATION = '/user/otp_verify/';
export const RESEND_OTP = '/user/resend_otp/';
export const CHANGE_PASSWORD = '/user/change_password/';
export const CONTACT_US = '/contactform';
export const SPOTLIGHT_PROJECTS = "/spotlight/project/"
export const ALL_PROJECT = '/projects/get';
export const DROPDOWN_FILTER = '/projects/dropdown';
export const TASK_TABLE = "/task/get/filter";
export const PRIORITY_TABLE = "/task_priority"
export const GRANT_COLLABORATION = "/project-docs/";
export const TEAM_MEMBER = "/team/project/";
export const TEAM_CONTRACTS = "/contracts/";
export const ADD_TASK = '/task';
export const GET_STREAM_TASK = '/stream/getstreams';
export const GET_ASSIGN_TASK = '/user';
export const  TASK_LIST_DROPDOWN= '/task/dropdown';
export const UPDATE_TASK='/task/';
export const SUB_TASK='/subtask/';
export const TASK_PRIORITY ='/task_priority/';
export const ASSIGNEE='/user';
export const MILESTONE_STATS ='/projects/stats/';
