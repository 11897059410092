import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    Grid,
    InputLabel,
    Autocomplete,
    InputAdornment,
    IconButton,
    Select, MenuItem,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "../../organisms/dateRangePicker/dateRangePicker";
import { Moment } from "moment";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
// import { Select, Option, selectClasses } from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo,
    EventInfo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import moment from "moment";
import apiService from "../../../service/apiService";
import { GET_ASSIGN_TASK, GET_STREAM_TASK, ASSIGNEE } from "../../../utils/constants";

import { ChangeEvent } from "react";import Loader from "../../Loader/Loader";
 interface AddModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (taskData: any) => void;
    initialStartDate?: string;
    initialEndDate?: string;
    projectId: string;
}

const options = [
    { label: 'The Shawshank Redemption', value: '1' },
    { label: 'The Godfather', value: '2' },
    { label: 'The Dark Knight', value: '3' },
    { label: 'Pulp Fiction', value: '4' },
    { label: 'The Lord of the Rings: The Return of the King', value: '5' },
];

const AddModal: React.FC<AddModalProps> = ({ open, onClose, onSave, initialStartDate, initialEndDate, projectId }) => {
    const [taskName, setTaskName] = useState("");
    const [priority, setPriority] = useState("Average");
    const [deadline, setDeadline] = useState("2024-10-11");
    const [subTasks, setSubTasks] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>(initialStartDate || '');
    const [endDate, setEndDate] = useState<string>(initialEndDate || '');
    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [activeButton, setActiveButton] = useState<string>('');
    const [streamOptions, setStreamOptions] = useState<{ id: string, name: string }[]>([]);
    const [assigneeOptions, setAssigneeOptions] = useState<{ id: string, first_name: string }[]>([]);
    const [reviewerOptions, setReviewerOptions] = useState<{ id: string, first_name: string }[]>([]);
    const [reporterOptions, setReporterOptions] = useState<{ id: string, first_name: string }[]>([]);
    const [usersList, setUsersList] = useState<{ id: string, first_name: string }[]>([]);
    const [assignUser, setAssignUser] = useState<string>("");
    const handleDateChange = (newStartDate: Moment, newEndDate: Moment) => {
        setStartDate(newStartDate.format('YYYY-MM-DD'));
        setEndDate(newEndDate.format('YYYY-MM-DD'));
    };
    const [tasks, setTasks] = useState<{ id: number | null; title: string; assignedTo: string | string[] }[]>([]);

    const [assigneeUserOptions, setAssigneeUserOptions] = useState<{ id: string; first_name: string }[]>([]);
    const [loading, setLoading] = useState(false);


    const [newTask, setNewTask] = useState<string>("");
    const [assignTo, setAssignTo] = useState<string>("");
    const [taskFormData, setTaskFormData] = useState({
        title: '',
        description: '',
        status: '',
        assigned_user_id: [] as string[],
        priority: '',
        reviewer_id: [] as string[],
        reporter_id: [] as string[],
        stream_name: '',
    });


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTaskFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (
        event: React.ChangeEvent<{}>,
        newValue: { id: string, name: string } | null
    ) => {
        setTaskFormData((prevData) => ({
            ...prevData,
            stream_name: newValue ? newValue.id : '',  // Store the selected stream's id
        }));
    };



    const handleAssignToChange = (
        event: React.ChangeEvent<{}>,
        newValue: { id: string; first_name: string }[]
    ) => {
        setTaskFormData({
            ...taskFormData,
            assigned_user_id: newValue.map((assignee) => assignee.id),
        });
    };
    const handleReviewerChange = (
        event: React.ChangeEvent<{}>,
        newValue: { id: string; first_name: string }[]
    ) => {
        setTaskFormData({
            ...taskFormData,
            reviewer_id: newValue.map((reviewer) => reviewer.id),
        });
    };
    const handleReporterToChange = (
        event: React.ChangeEvent<{}>,
        newValue: { id: string; first_name: string }[]
    ) => {
        setTaskFormData({
            ...taskFormData,
            reporter_id: newValue.map((reporter) => reporter.id),
        });
    };

    const handleSave = () => {
        const selectedStream = streamOptions.find(stream => stream.name === taskFormData.stream_name);
        const streamId = selectedStream ? selectedStream.id : '';
        const taskPayload = {
            title: taskFormData.title,
            description: taskFormData.description,
            status: "To Do",
            assigned_user_id: taskFormData.assigned_user_id,
            start_date: startDate,
            end_date: endDate,
            priority: activeButton,
            reviewer_id: taskFormData.reviewer_id,
            reporter_id: taskFormData.reporter_id,
            stream_name: streamId,
            tasks: tasks

        };
        onSave(taskPayload);
    };

    const handleAddTask = () => {
        if (newTask.trim()) {
            const newTaskItem = {
                id: Date.now(),  
                title: newTask,
                assignedTo: []  
            };
            setTasks([...tasks, newTaskItem]);
            setNewTask("");  
        }
    };

    const handleDeleteTask = (id: number | null) => {
        if (id != null) {
            setTasks(tasks.filter(task => task.id !== id));
        }
    };
    const handleAssignUserChange = async (taskId: number | null, newAssigneeIds: string[]) => {
        const updatedTasks = tasks.map((task) =>
            task.id === taskId
                ? {
                    ...task,
                    assignedTo: newAssigneeIds,
                }
                : task
        );

        setTasks(updatedTasks);
    };
    const handleEditorChange = (event: EventInfo<string, unknown>, editor: ClassicEditor) => {
        const data = editor.getData();
        setTaskFormData(prevData => ({
            ...prevData,
            description: data
        }));
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, status: string) => {
        setSelectedStatus(status);
        setActiveButton(status);
        setTaskFormData((prevData) => ({
            ...prevData,
            priority: status, 
        }));
    };
    
    useEffect(() => {
        if (projectId) {
            fetchStreams(projectId);
        }
        fetchAssignTo();
        fetchAssignUserList();
    }, [projectId]);
    const fetchStreams = async (projectId: string) => {
        try {
            setLoading(true);
            const response = await apiService.postApi(GET_STREAM_TASK, {
                projectId: projectId
            });
            const streams = response.data.data.stream || [];
            // Save both id and name of the streams
            setStreamOptions(streams.map((stream: { id: string, name: string }) => ({
                id: stream.id,
                name: stream.name, // Save the name for display in Autocomplete
            })));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching streams:', error);
        }
    };

    const fetchAssignTo = async () => {
        try {
            setLoading(true);
            const response = await apiService.getApi(GET_ASSIGN_TASK, {
                params: {
                    projectId: projectId,
                },
            });
            if (response && response.data) {
                const assignees = response.data.user_details;
                const assignee = assignees.map((assignee: any) => ({
                    id: assignee.id,
                    first_name: assignee.first_name,
                }));
                setAssigneeOptions(assignee);
                setReviewerOptions(assignee);
                setReporterOptions(assignee);
            } else {
                alert('Failed to fetch assignees.');
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Error fetching assignees:', error);
        }
    };
    const fetchAssignUserList = async () => {
        try {
            setLoading(true);
            const response = await apiService.getApi(`${ASSIGNEE}?project_id=${projectId}&reviewer=true&reporter=true`);
            if (response && response.data?.user_details) {
                const assignees = response.data.user_details.map((user: any) => ({
                    id: user.id,
                    first_name: user.first_name,
                }));
                setAssigneeUserOptions(assignees); 
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching assignees:", error);
        }
    };
 
    return (
        <Box>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="create-task-modal"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "auto"
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: 24,
                        width: "750px",
                        padding: "24px",
                        overflow: "auto",
                        maxHeight: '90%'
                    }}
                >
                    <Loader loading={loading} />
                    <Typography variant="h5" fontWeight="bold" display={'flex'} alignItems={'center'}
                        sx={{ fontWeight: '600' }} color="#103055" mb={2}>
                        Create Task <StarOutlineOutlinedIcon sx={{ cursor: 'pointer', ml: 2 }} />
                    </Typography>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box sx={{ mb: '0.5rem' }}>
                                            <InputLabel
                                                htmlFor="title"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Task Name
                                            </InputLabel>
                                            <TextField
                                                id="title"
                                                name="title"
                                                variant="outlined"
                                                fullWidth
                                                value={taskFormData.title}
                                                onChange={handleInputChange}
                                                required
                                                placeholder="Type Task Name"
                                                className={styles.inputField}
                                                InputProps={{
                                                    sx: {
                                                        height: '38px',
                                                        fontSize: '14px',
                                                        padding: '0',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel
                                            htmlFor="first_name"
                                            className={styles.labelText}
                                            sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                        >
                                            Description
                                        </InputLabel>
                                        <Box className="ckeditor-wrapper"
                                            mb={2}
                                            sx={{
                                                '.ck-editor__editable': {
                                                    minHeight: '188px', padding: '0 25px', borderRadius: '0.25rem', fontFamily: 'Inter', background: 'transparent !important', display: 'block',
                                                    '::before': {
                                                        color: '#7796B8'
                                                    },
                                                    '.ck-placeholder': { fontFamily: 'Inter', color: '#7796B8' },
                                                },
                                                border: '1px solid #5E8CBE',
                                                borderRadius: '0.25rem',
                                                backgroundColor: '#F5FAFF'
                                            }}>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    placeholder: "Type Your Task Details",
                                                    toolbar: [
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'heading',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        '|',
                                                        'link',
                                                        'insertTable',
                                                        'mediaEmbed',
                                                        '|',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'indent',
                                                        'outdent',
                                                    ],
                                                    plugins: [
                                                        Bold,
                                                        Essentials,
                                                        Heading,
                                                        Indent,
                                                        IndentBlock,
                                                        Italic,
                                                        Link,
                                                        List,
                                                        MediaEmbed,
                                                        Paragraph,
                                                        Table,
                                                        Undo,
                                                    ],
                                                }}
                                                onChange={handleEditorChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            initialStartDate={startDate ? moment(startDate) : undefined}
                                            initialEndDate={endDate ? moment(endDate) : undefined}
                                            onDateChange={handleDateChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2} mb={1}>
                                    <Grid item xs={6}>
                                        <Box>

                                            <InputLabel
                                                htmlFor="assign_to"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Assign To
                                            </InputLabel>
                                            <Autocomplete
                                                multiple
                                                options={assigneeOptions}
                                                disablePortal
                                                id="assign_to"
                                                className={styles.inputField}
                                                onChange={handleAssignToChange}
                                                value={assigneeOptions.filter((option) =>
                                                    taskFormData.assigned_user_id.includes(option.id)
                                                )}
                                                getOptionLabel={(option) => option.first_name || ''}
                                                renderInput={(params) => <TextField {...params} placeholder="Search by Name or Email" />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>

                                            <InputLabel
                                                htmlFor="stream"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Stream
                                            </InputLabel>
                                            <Autocomplete
                                                options={streamOptions}
                                                disablePortal
                                                id="Stream"
                                                className={styles.inputField}
                                                value={streamOptions.find(option => option.name === taskFormData.stream_name) || null}
                                                onChange={(e, newValue) => {
                                                    setTaskFormData({
                                                        ...taskFormData,
                                                        stream_name: newValue ? newValue.name : ''
                                                    });
                                                }}

                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search by Stream"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start" sx={{ margin: '0' }}>
                                                                    <SearchIcon sx={{ fontSize: '20px' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '38px',
                                                            },
                                                            '& .MuiAutocomplete-inputRoot': {
                                                                flexWrap: 'wrap',
                                                                maxHeight: '38px',
                                                                paddingTop: '0px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />

                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Box>

                                            <InputLabel
                                                htmlFor="reviewer"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Reviewer
                                            </InputLabel>
                                            <Autocomplete
                                                multiple
                                                options={reviewerOptions}
                                                disablePortal
                                                id="reviewer"
                                                onChange={handleReviewerChange}
                                                value={reviewerOptions.filter((option) =>
                                                    taskFormData.reviewer_id.includes(option.id)
                                                )}
                                                className={styles.inputField}
                                                getOptionLabel={(option) => option.first_name || ''}
                                                renderInput={(params) => <TextField {...params} placeholder="Search by Name or Email" />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <InputLabel
                                                htmlFor="reporter"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Reporter
                                            </InputLabel>
                                            <Autocomplete
                                                multiple
                                                options={reporterOptions}
                                                disablePortal
                                                id="reporter"
                                                onChange={handleReporterToChange}
                                                value={reporterOptions.filter(option => taskFormData.reporter_id.includes(option.id)) || []}
                                                className={styles.inputField}
                                                getOptionLabel={(option) => option.first_name || ''}
                                                renderInput={(params) => <TextField {...params} placeholder="Search by Name or Email" />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel
                                            htmlFor="first_name"
                                            className={styles.labelText}
                                            sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                        >
                                            Set The Priority
                                        </InputLabel>
                                        <Box py={2.5} px={2} sx={{ border: '1px solid #5E8CBE', background: '#F5FAFF', textAlign: 'center', borderRadius: '0.25rem' }}>
                                            <Button
                                                className={styles.priorityBtn}
                                                sx={{
                                                    color: '#438733',
                                                    backgroundColor: '#D5F6AB',
                                                    '&:hover': {
                                                        backgroundColor: '#A8E39A',
                                                    },
                                                    border: activeButton === 'Average' ? '2px solid #388E3C' : 'none',  // Apply border for clicked button
                                                    background: activeButton === 'Average' ? '#A8E39A' : '#D5F6AB',  // Darken the background for active button                                          
                                                }}
                                                value="Average"
                                                onClick={(e) => handleButtonClick(e, 'Average')}
                                            >
                                                Average
                                            </Button>
                                            <Button
                                                className={styles.priorityBtn}
                                                sx={{
                                                    color: '#E10026',
                                                    backgroundColor: '#FF8FAF',
                                                    '&:hover': {
                                                        backgroundColor: '#ff739b',
                                                    },
                                                    border: activeButton === 'Urgent' ? '2px solid #C62828' : 'none',
                                                    background: activeButton === 'Urgent' ? '#FF4B69' : '#FF8FAF',
                                                }}
                                                value="Urgent"
                                                onClick={(e) => handleButtonClick(e, 'Urgent')}
                                            >Urgent</Button>
                                            <Button
                                                className={styles.priorityBtn}
                                                sx={{
                                                    color: '#FF8000',
                                                    backgroundColor: '#FFEAB4',
                                                    '&:hover': {
                                                        backgroundColor: '#ffe6a5',
                                                    },
                                                    border: activeButton === 'High' ? '2px solid #F57C00' : 'none',
                                                    background: activeButton === 'High' ? '#FFCA4D' : '#FFEAB4',
                                                }}
                                                value="High"
                                                onClick={(e) => handleButtonClick(e, 'High')}
                                            >High</Button>
                                            <Button className={styles.priorityBtn}
                                                sx={{
                                                    color: '#1770D9',
                                                    backgroundColor: '#B9D9FF',
                                                    '&:hover': {
                                                        backgroundColor: '#a4ceff',
                                                    },
                                                    border: activeButton === 'Low' ? '2px solid #1565C0' : 'none',
                                                    background: activeButton === 'Low' ? '#81B9FF' : '#B9D9FF',
                                                }}
                                                value="Low"
                                                onClick={(e) => handleButtonClick(e, 'Low')}
                                            >Low</Button>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} mt={1}>
                                        <InputLabel
                                            htmlFor="first_name"
                                            className={styles.labelText}
                                            sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                        >
                                            Create Sub Task
                                        </InputLabel>
                                        <Box py={2.5} px={2} sx={{ border: '1px solid #5E8CBE', background: '#F5FAFF', textAlign: 'center', borderRadius: '0.25rem' }}>
                                            {/* Task Input Section */}
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Type Your Task"
                                                        variant="outlined"
                                                        size="small"
                                                        value={newTask}
                                                        onChange={(e) => setNewTask(e.target.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleAddTask} edge="end" color="primary">
                                                                        <AddIcon className={styles.plusIcon} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ mt: 2, overflowY: "auto", height: '200px' }}>
                                                {tasks.map((task) => (
                                                    <Grid container className={styles.subTasks} key={task.id}>
                                                        <Grid item xs={6} sx={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                                            <Typography>{task.title}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                
                                                                
                                                                value={task.assignedTo || []}  
                                                                onChange={(e) => handleAssignUserChange(task.id, Array.isArray(e.target.value) ? e.target.value : [e.target.value])}
                                                                displayEmpty
                                                                sx={{ height: "40px", width: "120px", backgroundColor: "#dfedff" }}
                                                            >
                                                                <MenuItem value="" disabled>Assign To</MenuItem>
                                                                {assigneeUserOptions.map((option) => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.first_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={2} textAlign={'right'}>
                                                            <DeleteIcon onClick={() => handleDeleteTask(task.id)} sx={{ color: "#7796B8", cursor: 'pointer' }} />
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" gap={1} mt={3}>
                            <Button variant="contained" color="primary" sx={{textTransform:"none"}} onClick={handleSave}>
                                Save
                            </Button>
                            <Button variant="outlined" sx={{textTransform:"none"}} onClick={onClose}>
                                Close
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default AddModal;