import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Divider
} from '@mui/material';
import styles from './styles.module.scss';
import { OptionInterface, Projects, OverView, CricularButton, CollaborationTableHeader, taskTableRows, priorityTableRows, CollaborationTableRow, teamMemberTableRow, contractsTableRow, TaskProgress, MilstoneStatsProgress } from '../../interface/adminDashboard';
import dashboardData from '../../data/adminDashboard.json';
import ProjectCard from '../../components/organisms/projectCard/projectCard';
import TitleText from '../../components/molecules/titleText/titleText';
import LinkableTable from '../../components/organisms/linkableTable/linkableTable';
import SearchAutocomplete from '../../components/atoms/searchAutocomplete/searchAutocomplete';
import DashboardHeaderAction from '../../components/molecules/dashboardHeaderAction/dashboardHeaderAction';
import CustomTable from '../../components/organisms/CustomeTable/CustomTable';
import apiService from '../../service/apiService';
import { ADD_TASK, ALL_PROJECT, DROPDOWN_FILTER, GRANT_COLLABORATION, PRIORITY_TABLE, TASK_TABLE, TEAM_CONTRACTS, TEAM_MEMBER, SUB_TASK, UPDATE_TASK, TASK_PRIORITY } from '../../utils/constants';
import { AxiosResponse } from 'axios';
import ExpandableTable from '../../components/organisms/expandableTable/expandableTable';
import ExpandableTeamTable from '../../components/organisms/expandableTeamTable/expandableTeamTable';
import { useNavigate } from 'react-router-dom';
import AdminProjectsDetails from '../adminProjectsDetails/adminProjectsDetails';
import AddModal from '../../components/modal/addModal/addModal';
import GraphFlow from '../../components/templates/graphFlow/graphFlow';
import EditModal from '../../components/modal/editModal/editModal';
import cogoToast from 'cogo-toast';
import Loader from '../../components/Loader/Loader';

const workingGroupBtn: CricularButton[] = dashboardData.workingButton;
const overview: OverView[] = dashboardData.overView;
const projectMilstone = dashboardData.projectsMilstone;
const headers: CollaborationTableHeader[] = dashboardData.tableData.headers;
const priorityHeader: CollaborationTableHeader[] = dashboardData.topPriority.headers;
const grantCollabHeader: CollaborationTableHeader[] = dashboardData.collaborationTable.headers;
const teamMemberHeader: CollaborationTableHeader[] = dashboardData.tableTeamMemberData.headers;
const teamContractsHeader: CollaborationTableHeader[] = dashboardData.tableContractsData.headers;

const AdminProjects: React.FC = () => {
    const [activeBtn, setActiveBtn] = useState(1);
    const [projectsData, setProjectsData] = useState<Projects[]>([]);
    const [optionsData, setOptionsData] = useState<Record<string, OptionInterface[]>>({});
    const [filteredProjects, setFilteredProjects] = useState<Projects[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string }>({});
    const [selectedProject, setSelectedProject] = useState<Projects | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [taskTableData, setTaskTableData] = useState<taskTableRows[]>([])
    const [priority, setPriority] = useState<priorityTableRows[]>([]);
    const [grantCollaboration, setGrantCollaboration] = useState<CollaborationTableRow[]>([]);
    const [project_id, setProject_id] = useState("");
    const [teamMember, setTeamMember] = useState<teamMemberTableRow[]>([]);
    const [teamContracts, setTeamContracts] = useState<contractsTableRow[]>([]);
    const [isTeamMemberExpanded, setTeamMemberExpanded] = useState(false);
    const [isTeamContractExpanded, setTeamContractExpanded] = useState(true);
    const fields = ['nation', 'region', 'state', 'city', 'county'];
    const [isProjectsDetailsView, setIsProjectsDetailsView] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
    const [projectSelectedId, setProjectSelectedId] = useState<string>("a8956b09-6ae6-4fdc-ad72-4075e0046ed3");
    // const [projectSelectedId, setProjectSelectedId] = useState<string | null>(null);
    const [selectedRowData, setSelectedRowData] = useState<taskTableRows | null>(null);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    // const [mileStone, setMileStone] = useState<MilstoneStatsProgress | null>(null);

    const handleSpanClick = (id: number) => {
        setActiveBtn(id);
    };

    const viewProjectsDetails = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsProjectsDetailsView(false);
    };

    const onBackBtn = () => {
        setIsProjectsDetailsView(true);
    }

    interface DropdownResponse {
        data: string[];
        field: string;
        filter: string;
    }

    const fetchDropdownOptions = async (field: string) => {
        try {
            const response: AxiosResponse<DropdownResponse> = await apiService.postApi(DROPDOWN_FILTER, {
                fields: field,
                filter: '',
            });

            if (response && response.data) {
                const { data, field: fieldName } = response.data;
                const transformedOptions = data.map((item: string, index: number) => ({
                    id: `${fieldName}-${index}`,
                    label: item,
                    value: item,
                }));
                setOptionsData((prev: any) => {
                    const updatedData = { ...prev, [fieldName]: transformedOptions };
                    return updatedData;
                });
            } else {
                alert(`Failed to fetch ${field} options.`);
            }
        } catch (error) {
            console.error(`Error fetching ${field} options:`, error);
        }
    };

    const fetchAllProject = async () => {
        try {
            const response = await apiService.postApi(ALL_PROJECT + '/1/100', {});
            if (response && response.data) {
                const projects = response.data.data;
                setProjectsData(projects);
                if (projects.length > 0) {
                    setSelectedProject(projects[0]);
                    fetchPriorityData(projects[0].id);
                    fetchGrantCollaboration(projects[0].id);
                    fetchTaskTableData(projects[0].id);
                    fetchTeamMember(projects[0].id);
                    fetchContracts(projects[0].id);
                    // fetchMilStoneStats(projects[0].id);
                }
            } else {
                alert('Failed to fetch project options.');
            }
        } catch (error) { }
    };

    const fetchTaskTableData = async (project_id: any) => {
        try {
            const response = await apiService.postApi(TASK_TABLE, {
                items_per_page: 10,
                page_number: 1,
                project_id: project_id
            });
            if (response && response.data) {
                setTaskTableData(response.data.data);
            } else {
                alert('Failed to fetch Data.');
            }
        } catch (error) { }
    }

    const fetchPriorityData = async (project_id: string) => {
        try {
            const response = await apiService.getApi(PRIORITY_TABLE + `/${project_id}`, {});
            if (response && response.data) {
                setPriority(response.data.data);
            } else {
                console.log("Failed to fetch Data.")
            }
        } catch { }
    }

    const fetchGrantCollaboration = async (project_id: string) => {
        try {
            const response = await apiService.getApi(GRANT_COLLABORATION + `${project_id}`, {});
            if (response && response.data) {
                setGrantCollaboration(response.data.data);
            } else {

            }
        } catch { }
    }

    const fetchTeamMember = async (project_id: string) => {
        try {
            const response = await apiService.getApi(TEAM_MEMBER + `${project_id}`, {});
            if (response && response.data) {
                setTeamMember(response.data.data);
            } else {

            }
        } catch { }
    }
    const fetchContracts = async (project_id: string) => {
        try {
            const response = await apiService.getApi(TEAM_CONTRACTS + `1/10/${project_id}`, {});
            if (response && response.data) {
                setTeamContracts(response.data.data);
            } else {

            }
        } catch { }
    }

    useEffect(() => {
        fields.forEach(field => {
            fetchDropdownOptions(field);
        });
        fetchAllProject();
    }, []);

    useEffect(() => {

        if (Object.values(selectedFilters).every(value => !value)) {
            setFilteredProjects(projectsData);
        } else {
            const filtered = projectsData.filter((project) => {
                return Object.entries(selectedFilters).every(([key, value]) => {
                    if (!value) return true;

                    if (key in project) {
                        const projectValue = project[key as keyof typeof project];
                        if (typeof projectValue === 'string') {
                            return projectValue.toLowerCase().includes(value.toLowerCase());
                        }
                        if (typeof projectValue === 'number') {
                            return projectValue.toString().includes(value);
                        }
                    }
                    return false;
                });
            });
            setFilteredProjects(filtered);
        }
    }, [selectedFilters, projectsData]);

    const handleFilterChange = (field: string, value: string) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCardClick = (index: number) => {
        const selected = projectsData[index];
        setSelectedProject(selected);
        fetchPriorityData(selected.id);
        fetchGrantCollaboration(selected.id);
        fetchTaskTableData(selected.id);
        fetchTeamMember(selected.id);
        fetchContracts(selected.id);
        setSelectedIndex(index);
        setProject_id(projectsData[index]?.id)
        // fetchMilStoneStats(selected.id)
    };

    const selectedIndexProject = projectsData[selectedIndex];
    const address = '123 Main Street, Kingston, NY';


    const toggleTeamMemberExpand = () => {
        setTeamMemberExpanded((prev) => !prev);
        setTeamContractExpanded(false);
    };

    const toggleTeamContractsExpand = () => {
        setTeamContractExpanded((prev) => !prev);
        setTeamMemberExpanded(false);
    };

    const handleAddClick = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSaveTask = async (taskData: any) => {
        
        const taskPayload = {
            title: taskData.title,
            description: taskData.description,
            project_id: selectedProject?.id,
            status: taskData.status,
            assigned_user_id: taskData.assigned_user_id,
            start_date: taskData.start_date,
            end_date: taskData.end_date,
            priority: taskData?.priority,
            reviewer_id: taskData.reviewer_id,
            reporter_id: taskData.reporter_id,
            stream: { id: taskData.stream_name }
        };

        try {
            setLoading(true);

            const response = await apiService.postApi(ADD_TASK, taskPayload);
            if (response) {
                const subTaskPayload = taskData?.tasks.map((item: any) => ({
                    task_id: response?.data?.data?.id,
                    name: item.title,
                    status: "To Do",
                    assigned_to: item.assignedTo
                }));
            

                setOpenModal(false);
                fetchTaskTableData(selectedProject?.id);
                const subtask = await apiService.postApi(SUB_TASK, subTaskPayload);
                cogoToast.success("Task Created sucessfully.");
            } else {
                cogoToast.success("Unable to create task");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error occurred:', error);
        }
        handleCloseModal();
        
    };
    const handleEditRowClick = (rowData: taskTableRows) => {
        setSelectedRowData(rowData);  
        setOpenEditModal(true);
    };

    const handlePriorityChange = (event: any, item: any) => {
        const payload = {
            taskid: item.id,
            priority: Number(event.target.innerText)
        };
        priorityChange(payload);
    }

    const priorityChange = async (payload: any) => {
        try {
            const response = await apiService.putApi(PRIORITY_TABLE, payload);
            if (response && response.data) {
                if (selectedProject?.id) {
                    fetchPriorityData(selectedProject.id);
                }
            } else {
                console.error('API Response error: No data returned.');
            }
        } catch (error) {
            console.error('Error updating priority:', error);
        }
    };


    // Handler to close the modal
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleSaveEdit = (updatedRowData: taskTableRows) => {
        setOpenEditModal(false);
    };

    const handleDelete = async (rowData: taskTableRows) => {
        const response = await apiService.deleteApi(`${UPDATE_TASK}${rowData?.id}`);
        fetchTaskTableData(selectedProject?.id);

    }

    const onFavoriteClick = async (rowData: taskTableRows) => {
        const favPayload =
        {
            taskid: rowData.id,
            streamid: rowData?.stream?.id,
            projectid: selectedProject?.id
        }
        if (rowData?.top_priority && parseInt(rowData?.top_priority) > 0) {
            await apiService.deleteApi(`${TASK_PRIORITY}${rowData?.id}`);
        } else {
            const response = await apiService.postApi(TASK_PRIORITY, favPayload);
        }

        fetchTaskTableData(selectedProject?.id);

    }


    return (
        <Box className={styles.admin}>
            <Loader loading={loading} />
            {isProjectsDetailsView ? (<Grid container>
                <Grid item xs={12}>
                    <Box className={styles.contents}>
                        <Typography className={styles.navbar} variant="h6" color='#2a2a2a'>
                            <h6><span>Project</span></h6>
                        </Typography>
                        <Box mb={2}>
                            <DashboardHeaderAction
                                selectedItem={'Project'}
                                address={address}
                                icon="/assets/images/edit.png"
                                // onIconClick={}
                                buttons={workingGroupBtn}
                                activeBtn={activeBtn}
                                onButtonClick={handleSpanClick}
                            />
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box position={"relative"}>
                                <Typography sx={{fontFamily:'Inter', color:'#2a2a2a', pl:2, fontWeight:600,fontSize:'1.5rem', position:'absolute', zIndex:1}}>Live map coming soon</Typography>
                                    <img width={'100%'} src="/assets/images/project-group-map.png" alt="map" />
                                </Box>
                                <Grid container spacing={2} mt={0}>
                                    {fields.map((field, index) => (
                                        <Grid item xs={12} md={6} lg={2.4} key={index}>
                                            <SearchAutocomplete
                                                options={optionsData[field] || []}
                                                placeholder={`Select ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                                                onChange={(event, value) => handleFilterChange(field, value)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Divider sx={{ background: '#CEDDED', margin: '1rem 0' }} />
                                <Box overflow={'auto'} pb={2}>
                                    <Grid container spacing={4} sx={{ flexWrap: 'nowrap' }}>
                                        {filteredProjects.map((project, index) => (
                                            <Grid
                                                key={project.id}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                className={`${styles.projects} ${selectedProject?.id === project.id ? styles.selectedCard : ''
                                                    }`}
                                                onClick={() => handleCardClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <ProjectCard
                                                    id={project.id}
                                                    name={project.name}
                                                    people_count={project.people_count}
                                                    donations={project.donations}
                                                    goal_percentage={project.goal_percentage}
                                                    description={project.description}
                                                    project_image={project.project_image}
                                                    project_links={project.project_links}
                                                    goal_type={project.goal_type}
                                                    startdate_home_page={project.startdate_home_page}
                                                    state={project.state}
                                                    city={project.city}
                                                    national={project.national}
                                                    nation={project.nation}
                                                    start_date={project.start_date}
                                                    borderColor={
                                                        selectedProject?.id === project.id ? '2px solid #73ACEF' : '1px solid #C1CEDB'
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                {selectedIndexProject && (
                                    <Box className={styles.overview} pt={2}>
                                        {overview.map((item) => (
                                            <TitleText
                                                key={item.title}
                                                title={item.title}
                                                description={selectedIndexProject.description}
                                            />
                                        ))}
                                    </Box>
                                )}
                                <Box>
                                    {projectMilstone.map((item) => (<TitleText title={item.title} />))}
                                    {/* <GraphFlow
                                        overallProgress={mileStone.percentage_total_task_project}
                                        pieData={pieChartData}
                                        taskProgress={mileStone.task_progress} /> */}
                                        <GraphFlow 
                                        projectId={selectedProject?.id || ""}
                                        />
                                </Box>

                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={7.5}>
                                            <Box className={styles.navbar} width={'100%'} mt={4}>
                                                <Typography variant="h3"><h3>Top Priorities</h3></Typography>
                                            </Box>
                                            {priority?.length > 0 ? (
                                                <CustomTable
                                                    headers={priorityHeader}
                                                    rows={priority}
                                                    onAddClick={handleAddClick}
                                                    onPriorityChange={handlePriorityChange}
                                                    hideEndDate={true}
                                                />
                                            ) : (
                                                <Typography className={styles.navbar}>
                                                    <h6>No priorities data available for the selected project.</h6>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} lg={4.5}>
                                            <Box className={styles.navbar} width={'100%'} mt={4}>
                                                <Typography variant="h3"><h3 style={{ color: '#103055' }}>EPA Grant Collaboration (BGN)</h3></Typography>
                                            </Box>
                                            {grantCollaboration?.length > 0 ? (
                                                <LinkableTable headers={grantCollabHeader} rows={grantCollaboration} />
                                            ) : (
                                                <Typography className={styles.navbar}>
                                                    <h6>No grant collaboration data available for the selected projects</h6>
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box className={styles.navbar} width={'100%'} mt={4}>
                                            <Typography variant="h3"><h3>Task List</h3></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {taskTableData?.length > 0 ? (<Box><CustomTable
                                            headers={headers}
                                            rows={taskTableData}
                                            onAddClick={handleAddClick}
                                            onViewDetails={viewProjectsDetails}
                                            onEditIconClick={handleEditRowClick}
                                            onDeleteClick={handleDelete}
                                            onFavoriteClick={onFavoriteClick}
                                        /><AddModal
                                                open={openModal}
                                                onClose={handleCloseModal}
                                                onSave={handleSaveTask}
                                                projectId={selectedProject?.id || ""}
                                            /></Box>) : (
                                            <Typography className={styles.navbar}>
                                                <h6>No task list data available for the selected projects</h6>
                                            </Typography>
                                        )}
                                        {selectedRowData && selectedRowData && (
                                            <EditModal
                                                open={openEditModal}
                                                onClose={handleCloseEditModal}
                                                rowData={selectedRowData}
                                                onSave={handleSaveEdit}
                                                projectId={selectedProject?.id || ""}
                                                fetchTaskTableData={fetchTaskTableData}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} margin={'2rem -1rem'}>
                                    <Grid item xs={isTeamMemberExpanded ? 9 : 3}>
                                        {teamMember?.length > 0 ? (
                                            <ExpandableTable
                                                headers={teamMemberHeader}
                                                rows={teamMember}
                                                isExpanded={isTeamMemberExpanded}
                                                onToggleExpand={toggleTeamMemberExpand}
                                            />
                                        ) : (
                                            <Typography className={styles.navbar}>
                                                <h6>No member list data available for the selected projects</h6>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={isTeamContractExpanded ? 9 : 3}>
                                        {teamContracts?.length > 0 ? (
                                            <ExpandableTeamTable
                                                headers={teamContractsHeader}
                                                rows={teamContracts}
                                                isExpanded={isTeamContractExpanded}
                                                onToggleExpand={toggleTeamContractsExpand}
                                            />
                                        ) : (
                                            <Typography className={styles.navbar}>
                                                <h6>No contracts list data available for the selected projects </h6>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>) : (
                <AdminProjectsDetails
                    onBackButton={onBackBtn}
                />
            )}
        </Box>
    )
}

export default AdminProjects