import React from "react";
import styles from './styles.module.scss';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    Avatar,
    Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CollaborationTableHeader, teamMemberTableRow, } from '../../../interface/adminDashboard';

interface TableProps {
    headers: CollaborationTableHeader[];
    rows: teamMemberTableRow[];
    isExpanded: boolean;
    onToggleExpand: () => void;
}


const ExpandableTable: React.FC<TableProps> = ({ rows, headers, isExpanded, onToggleExpand }) => {

    return (
        <Box className={styles.mainContainer}>
            <Typography className={styles.tableHead} variant="h3"><h3>Team Members</h3></Typography>
            <Box className={styles.box}>
                <TableContainer sx={{ overflowX: "hidden", paddingRight: "1rem", paddingLeft: '1rem' }}>
                    <Table
                        sx={{
                            "& .MuiTableCell-root": {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {isExpanded ?
                                    (<>
                                        {headers.map((header) => (
                                            <TableCell key={header.key} className={styles.headerText}>{header.label}</TableCell>
                                        ))}
                                    </>) : (<TableCell className={styles.headerText}>Name</TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index} className={styles.rows}>
                                    <TableCell className={styles.bodyText} sx={{ display: 'flex', width: '135px', alignItems: 'center' }} >
                                        <Avatar src={row.profile_image} className={styles.avatar} />{row.first_name}</TableCell>
                                    {isExpanded && (
                                        <>
                                            <TableCell className={styles.bodyText}>{row.role_name}</TableCell>
                                            <TableCell className={styles.bodyText}>#{row.id.split("-")[0]}{index}</TableCell>
                                            <TableCell className={styles.bodyText}>{row.email_address}</TableCell>
                                            <TableCell className={styles.linkText}>{row.user_task_count}</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className={styles.expandCollap} justifyContent="flex-end">
                    <IconButton className={styles.icon} onClick={onToggleExpand}>
                        {isExpanded ? <ArrowBackIosIcon className={styles.icon} /> : <ArrowForwardIosIcon className={styles.icon} />}
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default ExpandableTable;