import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

interface LoaderProps {
  loading: boolean; // Pass loading state as a prop
}

const Loader: React.FC<LoaderProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'fixed',  
            top: '50%',  
            left: '50%', 
            transform: 'translate(-50%, -50%)',  
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, 
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginTop: '8px', color: '#555555' }}>
            Loading...
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Loader;
