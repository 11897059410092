import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/dashboard/dashboard';
import Explore from '../pages/explore/explore';
import useAuth from '../hooks/useAuth';
import SignIn from '../pages/signIn/signIn';

const UserRoutes: React.FC = () => {
  const { isAuthenticated, signIn } = useAuth();

  return (
    <Routes>
      <Route
        path="/dashboard"
        element={isAuthenticated ? <Dashboard /> : <SignIn onSignin={signIn} />}
      />
      <Route
        path="/explore"
        element={isAuthenticated ? <Explore /> : <SignIn onSignin={signIn} />}
      />
      {/* <Route path="*" element={<SignIn onSignin={signIn} />} /> */}
    </Routes>
  );
};

export default UserRoutes;