// src/layouts/AdminLayout.tsx
import React from 'react';
import { DrawerContextProvider } from '../contexts/drawer-context';
import { Box, styled } from '@mui/material';
import { Main } from '../components/templates/sidebar/main';
import { Drawer } from '../components/templates/sidebar/drawer';

const OuterContainer = styled(Box)`
  display: flex;
  overflow: hidden;
  height: inherit;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: inherit;
`;

interface AdminLayoutProps {
    children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
    return (
        <DrawerContextProvider>
            <OuterContainer>
                <InnerContainer>
                    <Drawer />
                    <Main>{children}</Main>
                </InnerContainer>
            </OuterContainer>
        </DrawerContextProvider>
    );
};

export default AdminLayout;