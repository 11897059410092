import { useMediaQuery, useTheme, Drawer as MuiDrawer, styled, Box } from '@mui/material';
import { useDrawerContext } from '../../../contexts/drawer-context';
import SidebarHeader from './sideBarHeader';
import { MenuItemsList } from './MenuItemsList.tsx';
import useAuth from '../../../hooks/useAuth';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import styles from './styles.module.scss';

const drawerWidth = 220;
const collapsedWidth = 50;

const StyledDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'isOpened',
})<{ isOpened: boolean }>(({ isOpened, theme }) => ({
    width: isOpened ? drawerWidth : collapsedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiDrawer-paper': {
        width: isOpened ? drawerWidth : collapsedWidth,
        background: '#f1f5fc',
        border: '1px solid #c1cedb',
        overflowX: 'hidden',
        position: 'fixed',
        height: '98vh',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        borderRadius: '10px',
        marginLeft: '0.5rem',
        marginTop: '0.5rem'
    },
}));

export const Drawer = () => {
    const { isOpened, toggleIsOpened, menu } = useDrawerContext();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const { logout } = useAuth();

    return (
        <StyledDrawer
            sx={{ marginRight: '0.5rem' }}
            variant={isLargeScreen ? 'permanent' : 'temporary'}
            open={isLargeScreen ? true : isOpened}
            onClose={() => toggleIsOpened(!isOpened)}
            isOpened={isOpened}
        >
            <SidebarHeader />
            <MenuItemsList items={menu} />
            <Box sx={{ position: 'absolute', bottom: '1rem', width: '100%' }}>
                <Box onClick={logout} display={isOpened ? 'flex' : 'flex'} className={styles.logout}>
                    <LogoutOutlinedIcon className={styles.iconColor} /> Logout
                </Box>
            </Box>
        </StyledDrawer>
    );
};