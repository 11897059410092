import React, { useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    InputAdornment,
} from '@mui/material';
import styles from './styles.module.scss';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import dashboardData from '../../data/adminDashboard.json';
import IconButton from '../../components/atoms/iconButton/iconButton';

const dashboardButton = dashboardData.dashboardButton;
export type SidebarItem = 'Home' | 'Project' | 'Contract' | 'Events' | 'Profile' | 'Calendar' | 'Notifications' | 'Settings';

const AdminDashboard: React.FC = () => {
    const [selectedItem, setSelectedItem] = useState<SidebarItem>('Home');

    const getIcon = (iconName: string) => {
        if (iconName === 'CallMadeOutlinedIcon') {
            return <CallMadeOutlinedIcon sx={{ width: '1.25rem' }} />;
        }
        return null;
    };

    const onDashboard = () => { }

    return (
        <Box className={styles.admin}>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={styles.contents}>
                        <Box className={styles.navbar}>
                            <Box>
                                <Typography variant="h3" color='#2a2a2a'>
                                    <h3>Home</h3>
                                </Typography>
                                <Typography variant="h3">
                                    <h3><span>123 Main Street, Kingston, NY</span></h3>
                                </Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'grid', md: 'flex' }, alignItems: { md: 'baseline' } }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Search"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        sx: { height: '40px', border: '1px solid #D5D7DA' }
                                    }}
                                    sx={{ margin: { xs: '0 0 1rem 0', md: '0 1rem 0 0' } }}
                                />
                                {/* <Box className={styles.addBtn}>
                                    <IconButton
                                        buttonTitle={dashboardButton.buttonTitle}
                                        icon={getIcon(dashboardButton.icon)}
                                        onClick={onDashboard}
                                    />
                                </Box> */}
                            </Box>
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box position={'relative'}>
                                <Typography sx={{fontFamily:'Inter', color:'#2a2a2a', pl:2, fontWeight:600,fontSize:'1.5rem', position:'absolute', zIndex:1}}>Live map coming soon</Typography>
                                    <img width={'100%'} src="/assets/images/home-map.png" alt="map" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminDashboard;