import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Button,
    Avatar,
    AvatarGroup,
    Box,
    Tooltip,
} from '@mui/material';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/Star';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CollaborationTableHeader, Person, Stream } from '../../../interface/adminDashboard';
import { KeyboardArrowDown } from '@mui/icons-material';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';

interface TableRows {
    id: string;
    title?: string;
    description?: string;
    project_id?: string;
    status?: string;
    priority?: string;
    top_priority?: string;
    start_date?: string;
    end_date?: string;
    stream_id?: string;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    updated_by?: string;
    is_active?: boolean;
    is_deleted?: boolean;
    urgency?: string;
    stream?: Stream;
    stream_name?: string;
    task?: string;
    assigned_users?: Person[];
    reviewers?: Person[];
    reporters?: Person[];
    dueDate?: string;
    progress?: string;
    action?: string;
    steps?: string

}

interface TableProps<RowType extends { id: string }> {
    headers: CollaborationTableHeader[];
    rows: TableRows[];
    onAddClick: () => void;
    onViewDetails?: () => void;
    hideEndDate?: boolean;
    onEditIconClick?: (rowData: RowType) => void;
    onDeleteClick?: (item: RowType) => void;
    onFavoriteClick?: (item: RowType) => void;
    onPriorityChange?: (event: any, item: any) => void;

}

const CustomTable = <RowType extends { id: string }>({
    headers,
    rows,
    onAddClick,
    hideEndDate,
    onViewDetails,
    onEditIconClick,
    onDeleteClick,
    onFavoriteClick,
    onPriorityChange
}: TableProps<RowType>) => {
    const [favorites, setFavorites] = useState<{ [key: string]: boolean }>({});

    const handleDeleteClick = (item: RowType) => {
        if (onDeleteClick) {
            onDeleteClick(item);
        }
    };
    const handleFavoriteClick = (item: RowType) => {
        setFavorites((prev) => ({
            ...prev,
            [item.id]: !prev[item.id], // Toggle the state for this item's id
        }));

        if (onFavoriteClick) {
            onFavoriteClick(item); // Call the parent function if needed
        }
    };

    return (
        <Box className={styles.box}>
            <TableContainer sx={{ background: '#F6FAFF', borderRadius: '0.75rem' }}>
                <Table sx={{ minWidth: 650 }} aria-label="custom table">
                    <TableHead className={styles.tableHeadRow}>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header.key}>
                                    <Typography className={styles.headerText}>{header.label}</Typography>
                                </TableCell>
                            ))}
                            <TableCell>
                                {!hideEndDate && (<Button variant="contained" onClick={onAddClick} className={styles.btn}>
                                    Add
                                </Button>)}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                        {rows.map((item, index) => (
                            <TableRow key={item.id} onClick={() => onEditIconClick?.(item as RowType)} style={{ cursor: 'pointer' }}>
                                {item.top_priority && !hideEndDate ? (<TableCell className={styles.rowSrText}>{index + 1}</TableCell>) : (
                                    <TableCell>
                                        <Select
                                            key={index}
                                            placeholder={item.top_priority}
                                            onChange={(event: any) => onPriorityChange?.(event, item)}
                                            indicator={
                                                <KeyboardArrowDown
                                                    sx={{
                                                        padding: '0',
                                                        color: '#636b74'
                                                    }}
                                                />
                                            }
                                            sx={{
                                                width: 'fit-content',
                                                padding: '5px',
                                                backgroundColor: 'transparent',
                                                fontSize: '0.875rem',
                                                color: '#2a2a2a',
                                                fontWeight: 'bold',
                                                fontFamily: 'Inter',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 'none',
                                                },
                                                [`& .${selectClasses.indicator}`]: {
                                                    transition: '0.2s',
                                                    [`&.${selectClasses.expanded}`]: {
                                                        transform: 'rotate(-180deg)',
                                                    },
                                                },
                                            }}
                                        >
                                            {rows.map((option) => (<Option key={option.id} value={option.top_priority}>
                                                {option.top_priority}
                                            </Option>
                                            ))}
                                        </Select>
                                    </TableCell>
                                )}
                                <TableCell className={styles.rowText}>{item.priority}</TableCell>
                                <TableCell className={styles.rowText}>{item?.stream?.name || (item?.stream_name)}</TableCell>
                                <TableCell className={styles.linkText}>{`${item.steps} ${item.title}`}</TableCell>
                                {!hideEndDate && (<TableCell className={styles.rowText}>
                                    {item.assigned_users && (<AvatarGroup max={3} className={styles.avtarAlign}>
                                        {item.assigned_users.map((person, idx) => (
                                            <Tooltip title={person.name} arrow>
                                                <Avatar key={idx} src={person.image} className={styles.avatar} />
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>)}
                                </TableCell>)}
                                {!hideEndDate && (<TableCell className={styles.rowText}>
                                    {item.reviewers && (<AvatarGroup max={3} className={styles.avtarAlign}>
                                        {item.reviewers.map((person, idx) => (
                                            <Tooltip title={person.name} arrow>
                                                <Avatar key={idx} src={person.image} className={styles.avatar} />
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>)}
                                </TableCell>)}
                                {!hideEndDate && (<TableCell className={styles.rowText}>
                                    {item.reporters && (<AvatarGroup max={3} className={styles.avtarAlign}>
                                        {item.reporters.map((person, idx) => (
                                            <Tooltip title={person.name} arrow>
                                                <Avatar key={idx} src={person.image} className={styles.avatar} />
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>)}
                                </TableCell>)}
                                {!hideEndDate && (
                                    <TableCell className={styles.rowText}>
                                        {item.end_date
                                            ? new Date(item.end_date).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: 'short',
                                            })
                                            : ''}
                                    </TableCell>
                                )}
                                <TableCell className={styles.rowText}>{item.status}</TableCell>
                                <TableCell>
                                    <Box className={styles.action}>
                                        {!hideEndDate && (<DriveFileRenameOutlineOutlinedIcon cursor="pointer"
                                            onClick={() => onEditIconClick?.(item as RowType)}
                                        />)}

                                        {(favorites[item.id] || (item?.top_priority && parseInt(item?.top_priority) > 0)) ? (
                                            <StarIcon
                                                sx={{ color: 'gold' }}
                                                cursor="pointer"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleFavoriteClick(item as RowType);
                                                }}
                                            />
                                        ) : (
                                            <StarBorderOutlinedIcon
                                                cursor="pointer"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleFavoriteClick(item as RowType);
                                                }} 
                                            />
                                        )}
                                        {!hideEndDate && (<DeleteOutlineOutlinedIcon cursor="pointer"  
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDeleteClick(item as RowType);
                                            }}                                
                                        />)}
                                        
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box className={styles.viewAll} onClick={onViewDetails}>View All</Box>
            </TableContainer>
        </Box>
    );
};

export default CustomTable;