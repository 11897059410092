import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import PublicRoutes from './routes/public.routes';
import AdminRoutes from './routes/adminRoutes';
import UserLayout from './layouts/userLayout';
import UserRoutes from './routes/userRoutes';
import useAuth from './hooks/useAuth';
import PublicLayout from './layouts/publicLayout.tsx';
import AdminLayout from './layouts/adminLayout';

const App: React.FC = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    );
};

const AppContent: React.FC = () => {
    const location = useLocation();
    const { isAuthenticated, userRole } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div>
            {isAuthenticated ? (
                userRole === 'admin' ? (
                    <AdminLayout>
                        <AdminRoutes />
                    </AdminLayout>
                ) : userRole === 'user' ? (
                    <UserLayout>
                        <UserRoutes />
                    </UserLayout>
                ) : (
                    <PublicLayout>
                        <PublicRoutes />
                    </PublicLayout>
                )
            ) : (
                <PublicLayout>
                    <PublicRoutes />
                </PublicLayout>
            )}
        </div>
    );
};

export default App;