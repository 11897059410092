import { Toolbar, IconButton, Box } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useDrawerContext } from '../../../contexts/drawer-context';

export const SidebarHeader = () => {
    const { isOpened, toggleIsOpened } = useDrawerContext();
    return (
        <Box>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {isOpened ? <Box>
                    <img style={{ width: '65px', height: 'auto' }}
                        src="/assets/images/black-logo.png" alt="logo" />
                </Box> : <></>}
                <IconButton
                    color="inherit"
                    onClick={() => toggleIsOpened(!isOpened)}
                    sx={{ padding: { xs: 0, md: '-24px' }, marginLeft: { sm: '-0.75rem' } }}
                >
                    {isOpened ? <ArrowBackOutlinedIcon /> : <ArrowForwardOutlinedIcon />}
                </IconButton>
            </Toolbar>
        </Box>
    );
};

export default SidebarHeader;
