import React from 'react';
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';

const AdminProfile: React.FC = () => {
    return (
        <Box className={styles.profile}>
            <img src="/assets/images/coming-soon.png" alt="comming soon" />
            <Typography><p>Coming soon</p></Typography>
        </Box>
    )
}

export default AdminProfile;