import React from 'react';
import Header from '../components/organisms/header/header';

interface UserLayoutProps {
    children: React.ReactNode;
}

const UserLayout: React.FC<UserLayoutProps> = ({ children }) => {
    return (
        <div className="user-layout">
            <Header />
            <main>{children}</main>
        </div>
    );
};

export default UserLayout;