import { Link } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { IMenuItem } from '../../../interface/menuTypes';
import styles from './styles.module.scss';

type Props = IMenuItem & {
    selected?: boolean;
    onClick?: () => void;
};

export const MenuItem: React.FC<Props> = ({
    route,
    literal,
    Icon,
    selected,
    onClick,
}) => {
    const link = (
        <ListItem disablePadding sx={{ margin: '1rem 0' }}>
            <ListItemButton
                className={styles.listButtonBox}
                selected={selected}
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: '#1770D9',
                        color: '#FFFFFF',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#1770D9',
                        color: '#FFFFFF',
                    },
                    '&:hover': {
                        backgroundColor: '#308fff',
                        color: '#FFFFFF',
                        '& .MuiTypography-root': {
                            color: '#FFFFFF',
                        }
                    },
                    padding: '1rem',
                }}
                onClick={onClick}
            >
                <ListItemIcon
                    className={styles.iconHover}
                    sx={[
                        { minWidth: '30px' },
                        (theme) => ({
                            paddingRight: theme.spacing(1),
                        }),
                    ]}
                >
                    <Icon sx={{ color: selected ? '#FFFFFF' : 'inherit' }} />
                </ListItemIcon>
                <Typography
                    className={styles.listItem}
                    sx={{ color: selected ? '#FFFFFF' : '#4a4a4a' }}
                >{literal}
                </Typography>
            </ListItemButton>
        </ListItem>
    );

    return route
        ? <Link className={styles.linkText} to={route}>{link}</Link>
        : link;
};