import React, { useEffect, useState } from "react";
import { Modal, Typography, TextField, Grid, InputLabel, Autocomplete, InputAdornment, IconButton, Box, Button, Select, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import styles from '../addModal/styles.module.scss';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import cogoToast from 'cogo-toast';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HistoryIcon from '@mui/icons-material/History';
import { CircularProgress } from '@mui/material';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo,
    EventInfo
} from 'ckeditor5';
import moment, { Moment } from "moment";
import apiService from "../../../service/apiService";
import { GET_STREAM_TASK, GET_ASSIGN_TASK, ASSIGNEE } from "../../../utils/constants";
import { taskTableRows } from "../../../interface/adminDashboard";
import DateRangePicker from "../../organisms/dateRangePicker/dateRangePicker";
import SearchIcon from '@mui/icons-material/Search';
import "react-datepicker/dist/react-datepicker.css";
import 'ckeditor5/ckeditor5.css';
import { UPDATE_TASK, SUB_TASK } from "../../../utils/constants";
import Loader from "../../Loader/Loader";
interface EditModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (taskData: any) => void;
    initialStartDate?: string;
    initialEndDate?: string;
    projectId: string;
    rowData: taskTableRows;
    fetchTaskTableData: (project_id: string) => void;
}

const EditModal: React.FC<EditModalProps> = ({ open, onClose, onSave, rowData, fetchTaskTableData }) => {

    const [startDate, setStartDate] = useState<string>(moment(rowData?.start_date).format('YYYY-MM-DD') || '');
    const [endDate, setEndDate] = useState<string>(moment(rowData?.end_date).format('YYYY-MM-DD') || '');
    const [selectedStatus, setSelectedStatus] = useState<string>(rowData?.status || 'Average');
    const [activeButton, setActiveButton] = useState<string>('');
    const [streamOptions, setStreamOptions] = useState<{ id: string; name: string }[]>([]);

    const [assigneeOptions, setAssigneeOptions] = useState<{ id: string, first_name: string }[]>([]);
    const [reviewerOptions, setReviewerOptions] = useState<{ id: string, first_name: string }[]>([]);
    const [reporterOptions, setReporterOptions] = useState<{ id: string, first_name: string }[]>([]);
    const [assigneeUserOptions, setAssigneeUserOptions] = useState<{ id: string; first_name: string }[]>([]);
    const [assignUser, setAssignUser] = useState<string>("");
    const [taskFormData, setTaskFormData] = useState({
        title: rowData?.title || '',
        description: rowData?.description || '',
        status: rowData?.status || '',
        assigned_user_id: rowData?.assigned_users?.map(user => user.name) || [],
        priority: rowData?.priority || '',
        reviewer_id: rowData?.reviewers?.map(reviewer => reviewer.name) || [],
        reporter_id: rowData?.reporters?.map(reporter => reporter.name) || [],
        stream_name: rowData?.stream ? { id: rowData.stream.id, name: rowData.stream.name } : null,


    });
    const [tasks, setTasks] = useState<{ id: number | null, title: string, assignedTo: string }[]>([]);
    const [newTask, setNewTask] = useState<string>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (rowData?.project_id) {
            fetchStreams(rowData?.project_id);
            setActiveButton(rowData.priority);
            getSubtasks()
        }
        fetchAssignTo();
        fetchAssignUserList();
    }, [rowData?.project_id]);
    useEffect(() => {
        setActiveButton(rowData.priority);
        getSubtasks();

    }, []);
    const fetchStreams = async (projectId: string) => {
        try {
            const response = await apiService.postApi(GET_STREAM_TASK, { projectId });
            const streams = response.data.data.stream || [];
            // Store the full stream object (id and name)
            setStreamOptions(streams);
        } catch (error) {
            console.error('Error fetching streams:', error);
        }
    };
    const fetchAssignTo = async () => {
        try {
            const response = await apiService.getApi(GET_ASSIGN_TASK);

            const assignees = response.data.user_details;
            setAssigneeOptions(assignees.map((assignee: any) => ({ id: assignee.id, first_name: assignee.first_name })));
            setReviewerOptions(assignees);
            setReporterOptions(assignees);
        } catch (error) {
            console.error('Error fetching assignees:', error);
        }
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, status: string) => {
        setSelectedStatus(status);
        setActiveButton(status);
    };

    const handleAddTask = () => {
        if (newTask.trim()) {
            const newTaskItem = { id: null, title: newTask, assignedTo: "Unassigned" };
            setTasks([...tasks, newTaskItem]);
            setNewTask("");
        }
    };

    const handleDeleteTask = async(id: number | null) => {
        setTasks(tasks.filter(task => task.id !== id));
        try {
        const response = await apiService.deleteApi(`${SUB_TASK}delete/${id}`);
        getSubtasks();
            
        } catch (error) {
            
        }
    };

    const handleDateChange = (newStartDate: Moment, newEndDate: Moment) => {
        setStartDate(newStartDate.format('YYYY-MM-DD'));
        setEndDate(newEndDate.format('YYYY-MM-DD'));
    };

    const handleEditorChange = (event: EventInfo<string, unknown>, editor: ClassicEditor) => {
        const data = editor.getData();
        setTaskFormData(prevData => ({ ...prevData, description: data }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTaskFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const mapNameToId = (name: string, options: { id: string, first_name: string }[]) => {
        const option = options.find(option => option.first_name === name);
        return option ? option.id : null;
    };

    const handleUpdateTask = async () => {
        const updatedData = {
            id: rowData.id,
            project_id: rowData.project_id,
            title: taskFormData.title,
            description: taskFormData.description,
            status: taskFormData.status,
            priority: selectedStatus,
            start_date: startDate,
            end_date: endDate,
            stream: { id: taskFormData.stream_name?.id },
            assigned_user_id: taskFormData.assigned_user_id.map(name => mapNameToId(name, assigneeOptions)),
            reviewer_id: taskFormData.reviewer_id.map(name => mapNameToId(name, reviewerOptions)),
            reporter_id: taskFormData.reporter_id.map(name => mapNameToId(name, reporterOptions)),
        };

        try {
            setLoading(true);
            const response = await apiService.putApi(UPDATE_TASK, updatedData);
            onSave(response.data);
            fetchTaskTableData(rowData.project_id);
            cogoToast.success("Data Saved sucessfully.")

        } catch (error) {
            setLoading(false);
            cogoToast.error("Unable to save the data")
            console.error('Error updating task:', error);
        }
        setLoading(false);

    };
    const handleShareClick = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url)
            .then(() => {
                cogoToast.success('Link copied to your clipboard.');
            })
            .catch(err => {
                cogoToast.error('Unable to copy the link.');

            });
    };
    const fetchAssignUserList = async () => {
        try {
            const response = await apiService.getApi(`${ASSIGNEE}?project_id=${rowData?.project_id}&reviewer=true&reporter=true`);
            if (response && response.data?.user_details) {
                const assignees = response.data.user_details.map((user: any) => ({
                    id: user.id,
                    first_name: user.first_name,
                }));
                setAssigneeUserOptions(assignees); // Set assignee options dynamically
            }
        } catch (error) {
            console.error("Error fetching assignees:", error);
        }
    };

    const handleAssignUserChange = async (taskId: number | null, newAssigneeId: string) => {

        const updatedTasks = tasks.map((task) =>
            task.id === taskId ? { ...task, assignedTo: newAssigneeId } : task
        );
        setTasks(updatedTasks);

        if (!taskId) {
            const newTask = updatedTasks.find((task) => task.id === null);
            if (newTask) {
                const subTaskPayload = [{
                    task_id: rowData?.id,
                    name: newTask.title,
                    assigned_to: [newAssigneeId],
                    status: "To Do",
                }];
                try {
                    const response = await apiService.postApi(SUB_TASK, subTaskPayload);
                    if (response) {
                        getSubtasks();
                    }
                } catch (error) {
                    console.error('Error creating new subtask:', error);
                }
            }
        } else {
            const taskToUpdate = updatedTasks.find((task) => task.id === taskId);
            if (taskToUpdate) {
                const subTaskPayload = {
                    subtask_id: taskToUpdate.id,
                    name: taskToUpdate.title,
                    assign_to: [newAssigneeId],
                };

                try {
                    await apiService.putApi(`${SUB_TASK}update`, subTaskPayload);
                } catch (error) {
                    console.error('Error updating subtask:', error);
                }

            }
        }
    };

    const getSubtasks = async () => {
        try {
            const subTaskPayload = {
                task_id: rowData?.id,  // Use the correct task ID
                page_number: 1,
                item_per_page: 10,
            };

            const response = await apiService.postApi(`${SUB_TASK}getsubtasks`, subTaskPayload);

            // Prefill the tasks state with the fetched subtasks
            const subtasks = response.data.data.subtasks.map((subtask: any) => ({
                id: subtask.id,
                title: subtask.name,
                assignedTo: subtask.assigned_to || "Unassigned", 
            }));
            setTasks(subtasks); 
        } catch (error) {
            console.error('Error fetching subtasks:', error);
        }
    };

    return (
        <Box>
            <Modal open={open} onClose={onClose} aria-labelledby="create-task-modal" sx={{
                display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto"
            }}>

                <Box sx={{ backgroundColor: "white", borderRadius: "8px", boxShadow: 24, width: "750px", padding: "24px", overflow: "auto", maxHeight: '90%' }}>
                    <Loader loading={loading} />
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        display="flex"
                        alignItems="center"
                        sx={{ fontWeight: '600', display: 'flex', alignItems: 'center', gap: '8px' }}
                        color="#103055"
                        mb={2}
                    >
                        Onboarding

                        <StarOutlineOutlinedIcon sx={{ cursor: 'pointer' }} />
                        <Typography
                            className={styles.statusContainer}
                        >
                            {rowData?.status}
                        </Typography>


                        <Box sx={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
                            <IconButton onClick={handleShareClick}>
                                <ShareIcon />
                            </IconButton>
                            <IconButton>
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                    </Typography>

                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="title" className={styles.labelText}>Task Name</InputLabel>
                                        <TextField
                                            id="title"
                                            name="title"
                                            variant="outlined"
                                            fullWidth
                                            value={taskFormData.title}
                                            onChange={handleInputChange}
                                            required
                                            placeholder="Type Task Name"
                                            className={styles.inputField}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="description" className={styles.labelText}>Description</InputLabel>
                                        <Box className="ckeditor-wrapper" mb={2} sx={{ '.ck-editor__editable': { minHeight: '188px', padding: '0 25px' }, border: '1px solid #5E8CBE' }}>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    placeholder: "Type Your Task Details",
                                                    toolbar: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'link', 'insertTable', 'mediaEmbed', '|', 'bulletedList', 'numberedList', 'indent', 'outdent'],
                                                    plugins: [Bold, Essentials, Heading, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo],
                                                }}
                                                data={taskFormData.description}
                                                onChange={handleEditorChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            initialStartDate={startDate ? moment(startDate) : undefined}
                                            initialEndDate={endDate ? moment(endDate) : undefined}
                                            onDateChange={handleDateChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="assign_to" className={styles.labelText}>Assign To</InputLabel>
                                        <Autocomplete
                                            multiple
                                            options={assigneeOptions}
                                            disablePortal
                                            id="assign_to"
                                            className={styles.inputField}
                                            onChange={(e, newValue) => setTaskFormData({ ...taskFormData, assigned_user_id: newValue.map(assignee => assignee.first_name) })}  // Store names in taskFormData
                                            value={assigneeOptions.filter(option => taskFormData.assigned_user_id.includes(option.first_name))}
                                            getOptionLabel={(option) => option.first_name || ''}
                                            renderInput={(params) => <TextField {...params} placeholder="Search by Name or Email" />}
                                        />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="stream" className={styles.labelText}>Stream</InputLabel>
                                        <Autocomplete
                                            options={streamOptions} // Use the full stream objects
                                            getOptionLabel={(option) => option.name} // Display the stream's name in the dropdown
                                            value={taskFormData.stream_name || null} // Set the value to the Stream object or null
                                            onChange={(e, newValue) => {
                                                // Ensure that the stream_name is updated with the selected stream object or null
                                                setTaskFormData({
                                                    ...taskFormData,
                                                    stream_name: newValue || null, // If no selection, set it to null
                                                });
                                            }}
                                            renderInput={(params) => <TextField {...params} placeholder="Search by Stream" />}
                                        />





                                    </Grid>




                                    <Grid item xs={6}>
                                        <Box>
                                            <InputLabel
                                                htmlFor="reviewer"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Reviewer
                                            </InputLabel>
                                            <Autocomplete
                                                multiple
                                                options={reviewerOptions}
                                                disablePortal
                                                id="reviewer"
                                                onChange={(e, newValue) => setTaskFormData({ ...taskFormData, reviewer_id: newValue.map(reviewer => reviewer.first_name) })}  // Store names in taskFormData
                                                value={reviewerOptions.filter(option => taskFormData.reviewer_id.includes(option.first_name))}
                                                className={styles.inputField}
                                                getOptionLabel={(option) => option.first_name || ''}
                                                renderInput={(params) => <TextField {...params} placeholder="Search by Name or Email" />}
                                            />

                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <InputLabel
                                                htmlFor="reporter"
                                                className={styles.labelText}
                                                sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                            >
                                                Reporter
                                            </InputLabel>
                                            <Autocomplete
                                                multiple
                                                options={reporterOptions}
                                                disablePortal
                                                id="reporter"
                                                onChange={(e, newValue) => setTaskFormData({ ...taskFormData, reporter_id: newValue.map(repoter => repoter.first_name) })}
                                                value={reviewerOptions.filter(option => taskFormData.reporter_id.includes(option.first_name))}
                                                className={styles.inputField}
                                                getOptionLabel={(option) => option.first_name || ''}
                                                renderInput={(params) => <TextField {...params} placeholder="Search by Name or Email" />}
                                            />


                                        </Box>
                                    </Grid>
                                    {/* Priority Buttons */}
                                    <Grid item xs={12}>
                                        <InputLabel
                                            htmlFor="first_name"
                                            className={styles.labelText}
                                            sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                        >
                                            Set The Priority
                                        </InputLabel>
                                        <Box py={2.5} px={2} sx={{ border: '1px solid #5E8CBE', background: '#F5FAFF', textAlign: 'center' }}>
                                            <Button
                                                className={styles.priorityBtn}
                                                sx={{
                                                    color: '#438733',
                                                    backgroundColor: '#D5F6AB',
                                                    '&:hover': {
                                                        backgroundColor: '#A8E39A',
                                                    },
                                                    border: activeButton === 'Average' ? '2px solid #388E3C' : 'none',
                                                    background: activeButton === 'Average' ? '#A8E39A' : '#D5F6AB',
                                                }}
                                                value="Average"
                                                onClick={(e) => handleButtonClick(e, 'Average')}
                                            >
                                                Average
                                            </Button>
                                            <Button
                                                className={styles.priorityBtn}
                                                sx={{
                                                    color: '#E10026',
                                                    backgroundColor: '#FF8FAF',
                                                    '&:hover': {
                                                        backgroundColor: '#ff739b',
                                                    },
                                                    border: activeButton === 'Urgent' ? '2px solid #C62828' : 'none',
                                                    background: activeButton === 'Urgent' ? '#FF4B69' : '#FF8FAF',
                                                }}
                                                value="Urgent"
                                                onClick={(e) => handleButtonClick(e, 'Urgent')}
                                            >Urgent</Button>
                                            <Button
                                                className={styles.priorityBtn}
                                                sx={{
                                                    color: '#FF8000',
                                                    backgroundColor: '#FFEAB4',
                                                    '&:hover': {
                                                        backgroundColor: '#ffe6a5',
                                                    },
                                                    border: activeButton === 'High' ? '2px solid #F57C00' : 'none',
                                                    background: activeButton === 'High' ? '#FFCA4D' : '#FFEAB4',
                                                }}
                                                value="High"
                                                onClick={(e) => handleButtonClick(e, 'High')}
                                            >High</Button>
                                            <Button className={styles.priorityBtn}
                                                sx={{
                                                    color: '#1770D9',
                                                    backgroundColor: '#B9D9FF',
                                                    '&:hover': {
                                                        backgroundColor: '#a4ceff',
                                                    },
                                                    border: activeButton === 'Low' ? '2px solid #1565C0' : 'none',
                                                    background: activeButton === 'Low' ? '#81B9FF' : '#B9D9FF',
                                                }}
                                                value="Low"
                                                onClick={(e) => handleButtonClick(e, 'Low')}
                                            >Low</Button>
                                        </Box>
                                    </Grid>
                                    {/* Sub Tasks */}
                                    <Grid item xs={12} mt={1}>
                                        <InputLabel
                                            htmlFor="first_name"
                                            className={styles.labelText}
                                            sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                                        >
                                            Create Sub Task
                                        </InputLabel>
                                        <Box py={2.5} px={2} sx={{ border: '1px solid #5E8CBE', background: '#F5FAFF', textAlign: 'center' }}>
                                            {/* Task Input Section */}
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Type Your Task"
                                                        variant="outlined"
                                                        size="small"
                                                        value={newTask}
                                                        onChange={(e) => setNewTask(e.target.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleAddTask} edge="end" color="primary">
                                                                        <AddIcon className={styles.plusIcon} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ mt: 2, overflowY: "auto", height: '200px' }}>
                                                {tasks.map((task) => (
                                                    <Grid container className={styles.subTasks}>
                                                        <Grid item xs={6} sx={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                                            <Typography>{task.title}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                        <Select
                                                            value={task.assignedTo || ""}  
                                                            onChange={(e) => handleAssignUserChange(task.id, e.target.value)}
                                                            displayEmpty
                                                            sx={{
                                                                height: "40px",
                                                                width: "120px",
                                                                backgroundColor: "#dfedff",
                                                                fontSize: "13px",
                                                                color: task.assignedTo ? "#000000" : "red",
                                                                fontWeight: "500",
                                                                fontFamily: "Inter",
                                                                borderRadius: "8px",
                                                                border: "1px solid #A0A0A0",
                                                                "&:hover": {
                                                                    backgroundColor: "#c4dbf8",
                                                                },
                                                            }}
                                                            IconComponent={KeyboardArrowDown}
                                                        >
                                                             <InputLabel>Text</InputLabel>
                                                            <MenuItem value="" disabled>
                                                                Assign To
                                                            </MenuItem>
                                                            {assigneeUserOptions.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.first_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                        </Grid>
                                                        <Grid item xs={2} textAlign={'right'}>
                                                            <DeleteIcon onClick={() => handleDeleteTask(task.id)} sx={{ color: "#7796B8", cursor: 'pointer' }} />
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="column" gap={1} mt={4}>
                            <Box sx={{ borderTop: '1px solid #D8E1E9', marginBottom: '8px' }}></Box>

                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box display="flex" gap={2}>
                                    <Button variant="text" startIcon={<ChatBubbleOutlineIcon sx={{ color: '#000D26' }} />} sx={{ color: '#555555', textTransform: "none" }}>Comments</Button>
                                    <Button variant="text" startIcon={<HistoryIcon sx={{ color: '#000D26' }} />} sx={{ color: '#555555', textTransform: "none" }}>History</Button>
                                </Box>
                                <Box display="flex" gap={2}>
                                    <Button variant="contained" sx={{ textTransform: "none" }} color="primary" onClick={handleUpdateTask}>Save</Button>
                                    <Button variant="outlined" sx={{ textTransform: "none" }} onClick={onClose}>Close</Button>
                                </Box>
                            </Box>
                        </Box>


                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default EditModal;
