import {
    Dashboard as DashboardIcon,
    ShoppingCart as ShoppingCartIcon,
    People as PeopleIcon,
    AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { ROUTES } from './routes';
import { IMenuItem } from '../interface/menuTypes';

export const MENU_LIST: IMenuItem[] = [
    {
        route: ROUTES.home,
        literal: 'Home',
        Icon: HomeOutlinedIcon,
    },
    {
        route: ROUTES.project,
        literal: 'Project',
        Icon: ArticleOutlinedIcon,
    },
    {
        route: ROUTES.contract,
        literal: 'Contract',
        Icon: InsertDriveFileOutlinedIcon,
    },
    {
        route: ROUTES.profile,
        literal: 'Profile',
        Icon: AccountCircleOutlinedIcon,
    },
    {
        route: ROUTES.notification,
        literal: 'Notifications',
        Icon: NotificationsNoneOutlinedIcon,
    },
    {
        route: ROUTES.settings,
        literal: 'Settings',
        Icon: SettingsOutlinedIcon,
    },
];  