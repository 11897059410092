import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { WidthFull } from '@mui/icons-material';
import { TaskProgress } from '../../../interface/adminDashboard';
import apiService from '../../../service/apiService';
import { MILESTONE_STATS } from '../../../utils/constants';

interface ChartData {
    name: string;
    value: number;
    color?: string;
}
interface PhaseData {
    name: string;
    completed: boolean;
    percentage?: number;
    totalTasks: number;
    completedTasks: number;
    isStarted: boolean;
}
interface mileStoneProps {
    overallProgress: number;
    pieData: { name: string; value: number; color: string }[];
    taskProgress: TaskProgress[];
}
interface TaskProgressData {
    title: string;
    total_subtask: number;
    completed_subtasks: number;
    in_progress_subtasks: number;
    percentage_completed: number;
}

interface graphFlowProps {
    projectId: string;
}

const GraphFlow: React.FC<graphFlowProps> = ({ projectId }) => {
    const [milestone, setMileStone] = useState<any>()
    const [pieData, setPieData] = useState<ChartData[]>([])
    const [phases, setPhases] = useState<PhaseData[]>([])
    const [projectVisible, setProjectVisible] = useState<boolean>(true)
    const colors = ['#1770D9', '#CDDDEE', '#103055']

    const fetchMilStoneStats = useCallback(async () => {
        try {
            const response = await apiService.getApi(MILESTONE_STATS + projectId);
            if (response && response?.data?.data) {
                const milestones = response.data.data;
                setProjectVisible(response.data.data.project_visible)
                setMileStone(milestones);
                setPieData([
                    {
                        name: 'Completed',
                        value: milestones.percentage_completed_project,
                    },
                    {
                        name: 'Not Completed',
                        value: milestones.percentage_not_completed_project,
                    },
                    {
                        name: 'Work In Progress',
                        value: milestones.percentage_inprogress_project,
                    },
                ])

                const phases: PhaseData[] = milestones.task_progress.map((item: TaskProgressData) => ({
                    name: item.title,
                    completed: item.completed_subtasks === item.total_subtask,
                    percentage: item.percentage_completed,
                    totalTasks: item.total_subtask,
                    completedTasks: item.completed_subtasks,
                    isStarted: item.percentage_completed > 0
                }))
                setPhases(phases)
            } else {
                console.error("fetchMilStoneStats: ", response)
            }
        } catch (err) {
            console.error("fetchMilStoneStats: ", err)
        }
    }, [projectId])

    useEffect(() => {
        fetchMilStoneStats();
    }, [fetchMilStoneStats, projectId]);

    return (
        <Box className={styles.graph}>
            {projectVisible ? (<Grid container spacing={2}>
                <Grid item md={'auto'}>
                    <Box position="relative" width="220px" height="220px">
                        <ResponsiveContainer width="100%" height="100%">
                            {
                                pieData.length ? (
                                    <PieChart>
                                        <Pie
                                            data={pieData}
                                            dataKey="value"
                                            nameKey="name"
                                            innerRadius={55}
                                            outerRadius={90}
                                            startAngle={90}
                                            endAngle={-270}
                                            fill="#8884d8"
                                        >
                                            {pieData.map((_, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                ) : <></>
                            }
                        </ResponsiveContainer>

                        {/* Centered Text */}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <Typography>
                                <h5>{Math.ceil(milestone?.percentage_completed_project)}%</h5>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={8} my={'auto'}>
                    <Stack display={'block'} spacing={2} mt={1} className={styles.indicatorBox}>
                        <Typography><h6>Overall Progress</h6></Typography>
                        <Box display={'flex'}>
                            <Typography className={styles.box}><Box className={styles.CIndicator}></Box><p>Completed</p></Typography>
                            <Typography className={styles.box}><Box className={styles.WIndicator}></Box><p>Work In Progress</p></Typography>
                            <Typography className={styles.box}><Box className={styles.NCIndicator}></Box><p>Not Completed</p></Typography>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>) : (
                <Typography>
                    <h4>No analytical data available.</h4>
                </Typography>
            )}
            {projectVisible && (<Grid container justifyContent={'center'} mt={3}>
                <Grid item>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <img className={styles.activeflagImage} src="/assets/images/active-graph.png" alt="flag mountain" />
                    </Box>
                </Grid>

                {phases.map((phase, index) => (
                    <Grid key={index} item xs={2.8} sx={{ display: 'flex' }}>
                        <Box
                            alignItems="center"
                            textAlign="center"
                            className={styles.progressMap}
                            width={'100%'}
                        >
                            <Typography color={phase.isStarted ? '#1F438C' : '#A5ABBA'}>
                                <p>Completed {phase.percentage}%</p>
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={phase.percentage}
                                sx={{
                                    height: 4,
                                    my: 1,
                                    backgroundColor: '#C7D6E8',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#1770D9'
                                    },
                                }}
                            />
                            <Typography mt={1} color={phase.isStarted ? '#1F438C' : '#A5ABBA'}>
                                <small>{phase.name}</small>
                            </Typography>
                            <Typography color={phase.isStarted ? '#003BFF' : '#A5ABBA'}>
                                <strong>({phase.completedTasks}/{phase.totalTasks})</strong>
                            </Typography>
                        </Box>
                        <Box>
                            {
                                phase.completed ?
                                    (<img className={styles.activeflagImage} src="/assets/images/active-graph.png" alt="flag mountain" />) :
                                    (<img className={styles.flagImage} src="/assets/images/deactive-graph.png" alt="flag mountain" />)
                            }
                        </Box>
                    </Grid>
                ))}
            </Grid>)}
        </Box>
    );
};

export default GraphFlow;