import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from '../pages/adminDashboard/adminDashboard';
import useAuth from '../hooks/useAuth';
import SignIn from '../pages/signIn/signIn';
import AdminProjects from '../pages/adminProjects/adminProjects';
import AdminContract from '../pages/adminContract/adminContract';
import AdminProfile from '../pages/adminProfile/adminProfile';
import AdminNotification from '../pages/adminNotification/adminNotification';
import AdminSettings from '../pages/adminSettings/adminSettings';

const AdminRoutes: React.FC = () => {
  const { isAuthenticated, userRole, signIn } = useAuth();

  return (
    <Routes>
      <Route
        path="/admin"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminDashboard />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
      <Route
        path="/admin-projects"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminProjects />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
      <Route
        path="/admin-contract"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminContract />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
      <Route
        path="/admin-profile"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminProfile />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
      <Route
        path="/admin-notification"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminNotification />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
      <Route
        path="/admin-settings"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminSettings />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
      {/* <Route path="*" element={<SignIn onSignin={signIn} />} /> */}
    </Routes>
  );
};

export default AdminRoutes;