import axios from "axios";

// Set the base URL to your actual backend API URL
const API_URL = 'https://vpbackendapp.azurewebsites.net/api/v1'; // Update with your API's base URL

const getToken = () => {
    return localStorage.getItem('token');
};

const apiService = {
    getApi: (URL: string, params?: any) => {
        const token = getToken();
        return axios.get(`${API_URL}${URL}`, {
            params,
            headers: token ? { Authorization: `Bearer ${token}` } : {},
        });
    },

    postApi: (URL: string, data: any) => {
        const token = getToken();
        return axios.post(`${API_URL}${URL}`, data, {
            headers: {
                'Content-Type': 'application/json', // Ensure the content type is correct
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
        });
    },
    putApi: (URL: string, data: any) => {
        const token = getToken();
        return axios.put(`${API_URL}${URL}`, data, {
            headers: {
                'Content-Type': 'application/json', // Ensure the content type is correct
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
        });
    },
    deleteApi: (URL: string) => {
        const token = getToken();
        return axios.delete(`${API_URL}${URL}`, {
            headers: {
                'Content-Type': 'application/json', // Ensure the content type is correct
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
        });
    },
    

    // Other methods can be added here as needed
};

export default apiService;
