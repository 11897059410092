import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';

const StyledMain = styled('main')`
  height: 100%;
  width:100%;
  flex: 1,;
  overflow: auto;
`;

export const Main = ({ children }: PropsWithChildren<unknown>) => (
    <StyledMain>
        {children}
    </StyledMain>
);