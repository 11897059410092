import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Drawer, Container, Toolbar, AppBar, IconButton, Box, Typography, MenuItem, Button, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../atoms/customButton/customButton';
import useAuth from '../../../hooks/useAuth';
import { ArrowDropDown } from '@mui/icons-material';

const Header: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, logout, checkAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const blueButtonProps = {
    title: 'Join Us',
    color: '#FFFFFF',
    bgColor: '#1770D9',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: 'Inter',
    padding: '13px 20px',
  };

  const skyButtonProps = {
    title: 'Sign In',
    color: '#2a2a2a',
    bgColor: '#8FC3FF66',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: 'Inter',
    padding: '13px 20px',
  };

  const logoutButtonProps = {
    title: 'Logout',
    color: '#2a2a2a',
    bgColor: '#8FC3FF66',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: 'Inter',
    padding: '13px 20px',
  };

  const menuItems = !isAuthenticated
    ? [
      { text: 'Membership', href: '/membership' },
      { text: 'About Us', href: '/about-us' },
      { text: 'Contact Us', href: '/contact-us' },
    ]
    : [];

  const onBlueButtonClick = () => {
    navigate('/signin');
  };

  const onSkyButtonClick = () => {
    navigate('/signin');
  };

  const [exploreAnchorEl, setExploreAnchorEl] = useState<null | HTMLElement>(null);
  const [participateAnchorEl, setParticipateAnchorEl] = useState<null | HTMLElement>(null);

  const openExplore = Boolean(exploreAnchorEl);
  const openParticipate = Boolean(participateAnchorEl);

  const handleMenuOpen = (setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>, path: string = '') => {
    setAnchor(null);
    if (path) {
      navigate(path);
      setIsDrawerOpen(false);
    }
  };

  const handleExploreClose = (path: string) => {
    handleMenuClose(setExploreAnchorEl, path);
  };

  const handleParticipateClose = (path: string) => {
    handleMenuClose(setParticipateAnchorEl, path);
  };

  return (
    <AppBar position="sticky" className={styles.mainBox} sx={{ padding: { lg: '0 70px' } }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className={styles.toolbar}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton size="large" onClick={handleOpenDrawer} sx={{ padding: '0' }}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
              sx={{
                height: '100%',
                '& .MuiDrawer-paper': { height: '100%', display: 'flex', flexDirection: 'column' },
              }}
            >
              <IconButton
                size="large"
                onClick={handleCloseDrawer}
                sx={{ display: 'flex', justifyContent: 'flex-end', padding: '16px 20px' }}
              >
                <CloseIcon />
              </IconButton>
              {!isAuthenticated && (
                <Box className={styles.menuBarResponsive}>
                  <Button
                    className={styles.btnSelectResp}
                    onClick={handleMenuOpen(setExploreAnchorEl)}
                  >
                    Explore <ArrowDropDown />
                  </Button>
                  <Button
                    className={styles.btnSelectResp}
                    onClick={handleMenuOpen(setParticipateAnchorEl)}
                  >
                    Get Involved <ArrowDropDown />
                  </Button>
                  {menuItems.map((item) => (
                    <a key={item.text} onClick={handleCloseDrawer} href={item.href} style={{ textDecoration: 'none' }}>
                      <Typography className={styles.typo}>
                        <h1>{item.text}</h1>
                      </Typography>
                    </a>
                  ))}
                  <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    href="https://www.gofundme.com/f/voices-for-progress"
                    target="_blank"
                    className={styles.donateNowBtn}
                  >
                    Donate Now
                    <img src="/assets/images/donate-now.svg" alt="Donate Now" />
                  </Button>
                </Box>

                </Box>
              )}
            </Drawer>
          </Box>

          <a href="/" style={{ padding: '0' }}>
            <IconButton sx={{ padding: '0' }}>
              <img className={styles.logo} src="/assets/images/black-logo.png" alt="logo" />
            </IconButton>
          </a>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {!isAuthenticated ? (
              <Box className={styles.menuBar}>
                <Button className={styles.btnSelect} onClick={handleMenuOpen(setExploreAnchorEl)}>
                  Explore <ArrowDropDown />
                </Button>
                <Menu
                  anchorEl={exploreAnchorEl}
                  open={openExplore}
                  onClose={() => handleMenuClose(setExploreAnchorEl)}
                >
                  <MenuItem className={styles.menuBorder} onClick={() => handleExploreClose('/working-group')}>Working Groups</MenuItem>
                  <MenuItem className={styles.menuBorder} onClick={() => handleExploreClose('/projects')}>Projects</MenuItem>
                  <MenuItem className={styles.menuBorder} onClick={() => handleExploreClose('/contracts')}>Contracts</MenuItem>
                  <MenuItem onClick={() => handleExploreClose('/events')}>Events</MenuItem>
                </Menu>

                <Button className={styles.btnSelect} onClick={handleMenuOpen(setParticipateAnchorEl)}>
                  Get Involved <ArrowDropDown />
                </Button>
                <Menu
                  anchorEl={participateAnchorEl}
                  open={openParticipate}
                  onClose={() => handleMenuClose(setParticipateAnchorEl)}
                >
                  <MenuItem className={styles.menuBorder} onClick={() => handleParticipateClose('/volunteers')}>Volunteers</MenuItem>
                  <MenuItem className={styles.menuBorder} onClick={() => handleParticipateClose('/contributors')}>Contributors</MenuItem>
                  <MenuItem className={styles.menuBorder} onClick={() => handleParticipateClose('/activists')}>Activists & Change-Makers</MenuItem>
                  <MenuItem className={styles.menuBorder} onClick={() => handleParticipateClose('/project-manager')}>Project Managers</MenuItem>
                  <MenuItem className={styles.menuBorder} onClick={() => handleParticipateClose('/entrepreneurs')}>Entrepreneurs</MenuItem>
                  <MenuItem onClick={() => handleParticipateClose('/investors')}>Investors</MenuItem>
                </Menu>

                {menuItems.map((item) => (
                  <a key={item.text} href={item.href} className={styles.menuItemLink}>
                    {item.text}
                  </a>
                ))}
              </Box>
            ) : (
              <Box>
                <Typography>Welcome Back!</Typography>
              </Box>
            )}
          </Box>

          <Box className={styles.box} sx={{ gap: { xs: '0.5rem', md: '1.5rem' } }}>
            {isAuthenticated ? (
              <CustomButton {...logoutButtonProps} onClick={logout} />
            ) : (
              <>
                <CustomButton {...blueButtonProps} onClick={onBlueButtonClick} />
                {/* <CustomButton {...skyButtonProps} onClick={onSkyButtonClick} /> */}
                <Box sx={{ display: { xs: 'none', sm: "flex" } }}>
                  <Button variant="contained" href='https://www.gofundme.com/f/voices-for-progress' target='_blank' className={styles.donateNowBtn}>Donate Now
                    <img src="/assets/images/donate-now.svg" alt="" />
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;