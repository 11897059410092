import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss'
import {
    Box,
    Grid,
    Typography,
    Divider,
    Button,
    Stack,
    AvatarGroup,
    Avatar,
    TextField,
    InputAdornment,
    Popover,
    MenuItem,
    Checkbox,
    ListItemText,
    Tooltip
} from '@mui/material';
import { OptionInterface, Projects, CricularButton, CollaborationTableHeader, taskTableRows, taskOptionInterface, selectTaskTableRows } from '../../interface/adminDashboard';
import dashboardData from '../../data/adminDashboard.json';
import ProjectCard from '../../components/organisms/projectCard/projectCard';
import SearchAutocomplete from '../../components/atoms/searchAutocomplete/searchAutocomplete';
import DashboardHeaderAction from '../../components/molecules/dashboardHeaderAction/dashboardHeaderAction';
import CustomTable from '../../components/organisms/CustomeTable/CustomTable';
import apiService from '../../service/apiService';
import { ADD_TASK, ALL_PROJECT, DROPDOWN_FILTER, GET_ASSIGN_TASK, SUB_TASK, TASK_LIST_DROPDOWN, TASK_PRIORITY, TASK_TABLE, UPDATE_TASK } from '../../utils/constants';
import { AxiosResponse } from 'axios';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import SelectCustomTable from '../../components/organisms/selectCustomTable/selectCustomTable';
import AddModal from '../../components/modal/addModal/addModal';
import Loader from '../../components/Loader/Loader';
import EditModal from '../../components/modal/editModal/editModal';
import cogoToast from 'cogo-toast';

const workingGroupBtn: CricularButton[] = dashboardData.workingButton;
const headers: CollaborationTableHeader[] = dashboardData.projectTableData.headers;

interface AdminProjectsDetailsProps {
    onBackButton: () => void;
}

const AdminProjectsDetails: React.FC<AdminProjectsDetailsProps> = ({ onBackButton }) => {

    const [activeBtn, setActiveBtn] = useState(1);
    const [projectsData, setProjectsData] = useState<Projects[]>([]);
    const [optionsData, setOptionsData] = useState<Record<string, OptionInterface[]>>({});
    const [filteredProjects, setFilteredProjects] = useState<Projects[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string }>({});
    const [selectedProject, setSelectedProject] = useState<Projects | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [taskTableData, setTaskTableData] = useState<selectTaskTableRows[]>([])
    const [project_id, setProject_id] = useState("");
    const fields = ['nation', 'region', 'state', 'city', 'county'];
    const address = '123 Main Street, Kingston, NY';
    const [projectId, setProjectId] = useState<any>(null);
    const [activeFilter, setActiveFilter] = useState<any>(null);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [selectedNames, setSelectedNames] = useState<string[]>([]);
    const handleSpanClick = (id: number) => {
        setActiveBtn(id);
    };
    const [optionsTask, setOptionsTask] = useState<Record<string, taskOptionInterface[]>>({});
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<taskTableRows | null>(null);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    interface DropdownResponse {
        data: string[];
        field: string;
        filter: string;
    }

    const fetchDropdownOptions = async (field: string) => {
        try {
            const response: AxiosResponse<DropdownResponse> = await apiService.postApi(DROPDOWN_FILTER, {
                fields: field,
                filter: '',
            });

            if (response && response.data) {
                const { data, field: fieldName } = response.data;
                const transformedOptions = data.map((item: string, index: number) => ({
                    id: `${fieldName}-${index}`,
                    label: item,
                    value: item,
                }));
                setOptionsData((prev: any) => {
                    const updatedData = { ...prev, [fieldName]: transformedOptions };
                    return updatedData;
                });
            } else {
                alert(`Failed to fetch ${field} options.`);
            }
        } catch (error) {
            console.error(`Error fetching ${field} options:`, error);
        }
    };

    const fetchAllProjectOptions = async () => {
        try {
            const response = await apiService.postApi(ALL_PROJECT + '/1/100', {});
            if (response && response.data) {
                const projects = response.data.data;
                setProjectsData(projects);

                if (projects.length > 0) {
                    setSelectedProject(projects[0]);
                    fetchTaskTableData(projects[0].id);
                    setProjectId(projects[0].id);
                }
            } else {
                alert('Failed to fetch project options.');
            }
        } catch (error) { }
    };

    const fetchTaskTableData = async (project_id: string, filters?: { [key: string]: string }) => {
        try {
            setLoading(true);
            const filterData = filters || {};

            const payload: any = {
                items_per_page: 10,
                page_number: 1,
                project_id: project_id,
                status: activeFilter,
            };

            // Dynamically add filters to the payload
            Object.entries(filterData).forEach(([key, value]) => {
                if (key === 'urgency') {
                    payload['priority'] = value;
                } else if (key === 'stream') {
                    payload['stream'] = value;
                } else if (key === 'task/steps') {
                    payload['title'] = value;
                }
                else if (key === 'status') {
                    payload['status'] = value;
                }else if (key === 'assignee') {
                    payload['assigned_user_name'] = [value];
                }else if (key === 'reviewer') {
                    payload['reviewer_name'] = [value];
                }else if (key === 'reporter') {
                    payload['reporter_name'] = [value];
                }else if (key === 'due date') {
                    payload['end_date'] = value;
                } else {
                    payload[key] = value;
                }
            });
            const response = await apiService.postApi(TASK_TABLE, payload);
            if (response && response.data.data) {
                setTaskTableData(response.data.data);
            }else{
                setTaskTableData([]);
            }
            
        } catch (error) {
            setTaskTableData([]);
            setLoading(false);
            console.error('Error fetching task data:', error);
        }
        setLoading(false);
    };



    //********Call table data api when project filter change********** */

    useEffect(() => {
        fetchTaskTableData(projectId);
    }, [projectId, activeFilter, selectedNames])

    //********end */

    const fetchUserData = async (project_id: string) => {
        try {
            const response = await apiService.getApi(GET_ASSIGN_TASK, {
                params: {
                    projectId: project_id,
                },
            });
            if (response && response.data) {
                setUserDetails(response.data.user_details)
            } else {
                alert('Failed to fetch Data.');
            }
        } catch (error) { }
    }

    //********Call table data api when project filter change********** */

    useEffect(() => {
        fetchUserData(projectId);
    }, [projectId])

    //********end */

    useEffect(() => {
        fields.forEach(field => {
            fetchDropdownOptions(field);
        });
        fetchAllProjectOptions();
    }, []);

    useEffect(() => {

        if (Object.values(selectedFilters).every(value => !value)) {
            setFilteredProjects(projectsData);
        } else {
            const filtered = projectsData.filter((project) => {
                return Object.entries(selectedFilters).every(([key, value]) => {
                    if (!value) return true;

                    if (key in project) {
                        const projectValue = project[key as keyof typeof project];
                        if (typeof projectValue === 'string') {
                            return projectValue.toLowerCase().includes(value.toLowerCase());
                        }
                        if (typeof projectValue === 'number') {
                            return projectValue.toString().includes(value);
                        }
                    }
                    return false;
                });
            });
            setFilteredProjects(filtered);
        }
    }, [selectedFilters, projectsData]);

    const handleFilterChange = async (field: string, value: string) => {

        try {
            setLoading(true);
            setSelectedFilters((prev) => ({
                ...prev,
                [field]: value,
            }));
            const response = await apiService.postApi(TASK_LIST_DROPDOWN, {
                fields: field,
                filter: value,
                project_id:projectId
            });
            fetchTaskTableData(projectId, { ...selectedFilters, [field]: value });

        } catch (error) {
            console.log(error)
            setLoading(false);

        }
        setLoading(false);

    };

    const handleCardClick = (index: number) => {
        const selected = projectsData[index];
        setSelectedProject(selected);
        setProjectId(selected?.id);
        setSelectedIndex(index);
        setProject_id(projectsData[index]?.id)
    };

    const handleFilterClick = (filter: string) => {
        setActiveFilter(filter);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleEditClick = () => { };

    //************multi select user  */
    // Function to handle checkbox toggle

    const handleToggle = (firstName: string) => {
        setSelectedNames((prevSelectedNames) =>
            prevSelectedNames.includes(firstName)
                ? prevSelectedNames.filter((name) => name !== firstName) // Remove name if unchecked
                : [...prevSelectedNames, firstName] // Add name if checked
        );
    };

    const handleClearFilter = () => {
        setActiveFilter('');
        setSelectedNames([]);
        fetchTaskTableData(projectId)
    }


    const taskOptions = ['urgency', 'stream', 'task/steps', 'assignee', 'reviewer', 'reporter', 'dueDate'];
    useEffect(() => {
        taskOptions.forEach(field => {
            fetchDropdownData(field);
        });
    }, [projectId]);
    const fetchDropdownData = async (field: string) => {
        try {
            const response: AxiosResponse<DropdownResponse> = await apiService.postApi(TASK_LIST_DROPDOWN, {
                fields: field,
                filter: '',
                project_id:projectId
            });

            if (response && response.data) {
                const { data, field: fieldName } = response.data;
                const transformedOptions = data.map((item: string, index: number) => ({
                    id: `${fieldName}-${index}`,
                    label: item,
                    value: item,
                }));

                setOptionsTask((prev: any) => {
                    const updatedData = { ...prev, [fieldName]: transformedOptions };
                    return updatedData;
                });
            } else {
                console.error(`Failed to fetch ${field} options. Response:`, response);
            }
        } catch (error) {
            console.error(`Error fetching ${field} options:`, error);
        }
    };
    const handleAddClick = () => {
        setOpenModal(true)
    }
    const handleCloseClick = () => {
        setOpenModal(false)
    }
    const handleSaveTask = async (taskData: any) => {
        const taskPayload = {
            title: taskData.title,
            description: taskData.description,
            project_id: selectedProject?.id,
            status: taskData.status,
            assigned_user_id: taskData.assigned_user_id,
            start_date: taskData.start_date,
            end_date: taskData.end_date,
            priority: "test",
            reviewer_id: taskData.reviewer_id,
            reporter_id: taskData.reporter_id,
            stream: { id: taskData.stream_name }
        };

        try {


            const response = await apiService.postApi(ADD_TASK, taskPayload);
            if (response) {
                const subTaskPayload = taskData?.tasks.map((item: any) => ({
                    task_id: response?.data?.data?.id,
                    name: item.title,
                    status: "To Do"
                }));

                setOpenModal(false);
                fetchTaskTableData(projectId);
                const subtask = await apiService.postApi(SUB_TASK, subTaskPayload);
            } else {
                console.error('Failed to save task');
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
        handleCloseClick();
    };
    const handleEditRowClick = (rowData: taskTableRows) => {
        setSelectedRowData(rowData);  
        setOpenEditModal(true);
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };
    const handleSaveEdit = (updatedRowData: taskTableRows) => {
        setOpenEditModal(false);
    };
    const handleDelete = async (rowData: taskTableRows) => {
        const response = await apiService.deleteApi(`${UPDATE_TASK}${rowData?.id}`);
        fetchTaskTableData(projectId);

    }

    const onFavoriteClick = async (rowData: taskTableRows) => {
        const favPayload =
        {
            taskid: rowData.id,
            streamid: rowData?.stream?.id,
            projectid: selectedProject?.id
        }
        if (rowData?.top_priority && parseInt(rowData?.top_priority) > 0) {
            await apiService.deleteApi(`${TASK_PRIORITY}${rowData?.id}`);
        } else {
            const response = await apiService.postApi(TASK_PRIORITY, favPayload);
        }

        fetchTaskTableData(projectId);

    }
    return (
        <Box className={styles.admin}>
            <Loader loading={loading} />
            <Grid container>
                <Grid item xs={12}>
                    <Box className={styles.contents}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={styles.navbar} variant="h6" color='#2a2a2a'>
                                <h6><span>Project Details</span></h6>
                            </Typography>
                            <Typography className={styles.navbar} variant="h6" color='#2a2a2a'>
                                <Button onClick={onBackButton} variant='outlined'><KeyboardBackspaceOutlinedIcon />Back</Button>
                            </Typography></Box>
                        <Box mb={2}>
                            <DashboardHeaderAction
                                selectedItem={'Project'}
                                address={address}
                                icon="/assets/images/edit.png"
                                onIconClick={handleEditClick}
                                buttons={workingGroupBtn}
                                activeBtn={activeBtn}
                                onButtonClick={handleSpanClick}
                            />
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box position={"relative"}>
                                <Typography sx={{fontFamily:'Inter', color:'#2a2a2a', pl:2, fontWeight:600,fontSize:'1.5rem', position:'absolute', zIndex:1}}>Live map coming soon</Typography>
                                    <img width={'100%'} src="/assets/images/project-group-map.png" alt="map" />
                                </Box>
                                <Grid container spacing={2} mt={0}>
                                    {fields.map((field, index) => (
                                        <Grid item xs={12} md={6} lg={2.4} key={index}>
                                            <SearchAutocomplete
                                                options={optionsData[field] || []}
                                                placeholder={`Select ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                                                onChange={(event, value) => handleFilterChange(field, value)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Divider sx={{ background: '#CEDDED', margin: '1rem 0' }} />
                                <Box overflow={'auto'} pb={2}>
                                    <Grid container spacing={4} sx={{ flexWrap: 'nowrap' }}>
                                        {filteredProjects.map((project, index) => (
                                            <Grid
                                                key={project.id}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                className={`${styles.projects} ${selectedProject?.id === project.id ? styles.selectedCard : ''
                                                    }`}
                                                onClick={() => handleCardClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <ProjectCard
                                                    id={project.id}
                                                    name={project.name}
                                                    people_count={project.people_count}
                                                    donations={project.donations}
                                                    goal_percentage={project.goal_percentage}
                                                    description={project.description}
                                                    project_image={project.project_image}
                                                    project_links={project.project_links}
                                                    goal_type={project.goal_type}
                                                    startdate_home_page={project.startdate_home_page}
                                                    state={project.state}
                                                    city={project.city}
                                                    national={project.national}
                                                    nation={project.nation}
                                                    start_date={project.start_date}
                                                    borderColor={
                                                        selectedProject?.id === project.id ? '2px solid #73ACEF' : '1px solid #C1CEDB'
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box my={2}>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}>
                                                <Typography className={styles.navbar}
                                                    alignItems={'center'} variant="h3">
                                                    <h3 style={{ display: 'contents' }}>Task List {projectsData.length}</h3>
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    flexWrap="wrap"
                                                >
                                                    {["To Do", "Ready For Review", "Under Review", "Completed"].map(
                                                        (filter) => (
                                                            <Button
                                                                key={filter}
                                                                variant={activeFilter === filter ? "contained" : "outlined"}
                                                                onClick={() => handleFilterClick(filter)}
                                                                sx={{
                                                                    color: activeFilter === filter ? "primary" : "#7796B8",
                                                                    textTransform: "none",
                                                                    fontWeight: "bold",
                                                                    height: '40px',
                                                                    minWidth: "fit-content",
                                                                    borderRadius: "4px",
                                                                    background: activeFilter === filter ? "primary" : '#F3F3F3',
                                                                    boxShadow: activeFilter === filter ? "0px 2px 4px rgba(0,0,0,0.2)" : "none",
                                                                }}
                                                            >
                                                                {filter}
                                                            </Button>
                                                        )
                                                    )}
                                                    {/* <TextField
                                                        variant="outlined"
                                                        placeholder="Search"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        sx={{
                                                            maxHeightheight: "40px",
                                                            maxWidth: "140px",
                                                            "& .MuiOutlinedInput-root": { height: "40px" },
                                                            "& .MuiInputAdornment-root": { color: "#888" },
                                                        }}
                                                    /> */}
                                                    <div>
                                                        {/* Avatar Group Button */}
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ maxHeight: 40, borderColor: "#D5D7DA", borderRadius: "8px", padding: "0 8px" }}
                                                            onClick={handleClick}
                                                        >
                                                            <AvatarGroup max={3} sx={{ alignItems: "center", justifyContent: "center" }}>
                                                                {userDetails &&
                                                                    userDetails.map((item: any, index: number) => (
                                                                        <Tooltip title={item.name} arrow>
                                                                        <Avatar
                                                                            className={styles.avatar}
                                                                            key={index}
                                                                            sx={{ height: 33, width: 33 }}
                                                                            src={item.profile_image}
                                                                        />
                                                                        </Tooltip>
                                                                    ))}

                                                                {/* <Avatar sx={{ height: 33, width: 33 }} src="/static/images/avatar/4.jpg" /> */}
                                                            </AvatarGroup>
                                                            <KeyboardArrowDownOutlinedIcon />
                                                        </Button>

                                                        {/* Dropdown Menu */}
                                                        <Popover
                                                            open={open}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: "bottom",
                                                                horizontal: "center",
                                                            }}
                                                            transformOrigin={{
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            }}
                                                            sx={{ marginTop: "8px" }}
                                                        >
                                                            {userDetails &&
                                                                userDetails.map((user: any, index: number) => (
                                                                    <MenuItem key={user.id || index} onClick={() => handleToggle(user.first_name)}>
                                                                        <Checkbox
                                                                            checked={selectedNames.includes(user.first_name)}
                                                                        />
                                                                        <ListItemText primary={user.first_name} />
                                                                    </MenuItem>
                                                                ))}
                                                        </Popover>
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{ height: "40px", textTransform: "none" }}
                                                        onClick={() => handleClearFilter()}
                                                    >
                                                        Clear Filter
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleAddClick}
                                                        sx={{ height: "40px", textTransform: "none" }}
                                                    >
                                                        Add
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {taskTableData?.length > 0 ? (
                                            <Box><SelectCustomTable
                                                headers={headers}
                                                rows={taskTableData}
                                                options={headers.map((header) => ({
                                                    key: header.key, // Match the header key dynamically
                                                    options: optionsTask[header.key] || [], // Fetch the options dynamically based on the key
                                                }))}
                                                onFilterChange={(event, value) => { handleFilterChange(event, value) }}
                                                onEditIconClick={handleEditRowClick}
                                                onDeleteClick={handleDelete}
                                                onFavoriteClick={onFavoriteClick}
                                            /></Box>
                                        ) : (
                                            <Typography variant="body1" color="textSecondary" style={{ padding: "50px 0", textAlign: "center" }}>
                                                No task list data available for the selected projects
                                            </Typography>
                                        )}
                                        <AddModal
                                            open={openModal}
                                            onClose={handleCloseClick}
                                            onSave={handleSaveTask}
                                            projectId={selectedProject?.id || ""}
                                        />
                                        {selectedRowData && selectedRowData && (
                                            <EditModal
                                                open={openEditModal}
                                                onClose={handleCloseEditModal}
                                                rowData={selectedRowData}
                                                onSave={handleSaveEdit}
                                                projectId={selectedProject?.id || ""}
                                                fetchTaskTableData={fetchTaskTableData}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AdminProjectsDetails