import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment, { Moment } from 'moment';
import './style.scss';
import { Grid, InputLabel } from '@mui/material';

interface DateRangePickerProps {
    initialStartDate?: Moment;
    initialEndDate?: Moment;
    onDateChange?: (startDate: Moment, endDate: Moment) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
    initialStartDate = moment(),
    initialEndDate = moment(),
    onDateChange,
}) => {
    const [startDate, setStartDate] = useState<Moment>(initialStartDate);
    const [endDate, setEndDate] = useState<Moment>(initialEndDate);
    const datepickerRef = useRef<DatePicker>(null);

    const handleChange = ({ newStartDate, newEndDate }: { newStartDate?: Moment; newEndDate?: Moment }) => {
        const updatedStartDate = newStartDate || startDate;
        let updatedEndDate = newEndDate || endDate;

        if (updatedStartDate.isAfter(updatedEndDate)) {
            updatedEndDate = updatedStartDate;
        }

        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);

        if (onDateChange) {
            onDateChange(updatedStartDate, updatedEndDate);
        }

        datepickerRef.current?.setFocus();
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <InputLabel
                    htmlFor="first_name"
                    style={{ fontFamily: 'Inter', fontSize: '0.875rem', fontWeight: '600', color: '#103055' }}
                    sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                >
                    Start Date
                </InputLabel>
                <DatePicker
                    selected={startDate.toDate()}
                    selectsStart
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    onChange={(date) => handleChange({ newStartDate: moment(date) })}
                    className="custom-datepicker"
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel
                    htmlFor="first_name"
                    style={{ fontFamily: 'Inter', fontSize: '0.875rem', fontWeight: '600', color: '#103055' }}
                    sx={{ lineHeight: '30px', fontSize: '14px', color: '#103055' }}
                >
                    Deadline
                </InputLabel>
                <DatePicker
                    selected={endDate.toDate()}
                    selectsEnd
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    onChange={(date) => handleChange({ newEndDate: moment(date) })}
                    ref={datepickerRef}
                    className="custom-datepicker"
                />
            </Grid>
        </Grid>
    );
};

export default DateRangePicker;