import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { CollaborationTableRow } from '../../../interface/adminDashboard';

interface CollaborationTable {
  headers: { label: string; key: string }[];
  rows: CollaborationTableRow[];
}

const LinkableTable: React.FC<CollaborationTable> = ({ headers, rows }) => {
  return (
    <TableContainer className={styles.tableBox}>
      <Table aria-label="collaboration table">
        <TableHead>
          <TableRow className={styles.tableHeadRow}>
            {headers.map((header, index) => (
              <TableCell key={index} className={styles.tableCellBorder}>
                <Typography className={styles.tableCellHeadfont}>{header.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {rows.map((data) => (<TableRow className={styles.tableHeadRow}>
            <TableCell className={styles.rowText}>{data.folder}</TableCell>
            <TableCell className={`${styles.tableCellBorder},${styles.rowText}`} sx={{ border: '1px solid #D1D9E1' }}>
              <a href={data.meeting_links} className={styles.linkText} target="_blank">
                {data.meeting_links}
              </a>
            </TableCell>
            <TableCell className={styles.rowText}>
              {data.proposal}
              {data.frequently_used && (
                <a style={{ marginLeft: '0.25rem' }} className={styles.linkText} href={data.frequently_used} target="_blank">
                  Video Link
                </a>
              )}
            </TableCell>
          </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LinkableTable;