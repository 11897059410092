import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
    AvatarGroup,
    Box,
    Tooltip
} from '@mui/material';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CollaborationTableHeader, Person, Stream, taskOptionInterface } from '../../../interface/adminDashboard';
import { KeyboardArrowDown } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';

interface TableRows {
    id: string;
    title?: string;
    task_handle_links: string;
    description?: string;
    project_id?: string;
    status?: string;
    priority?: string;
    top_priority?: string;
    start_date?: string;
    end_date?: string;
    stream_id?: string;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    updated_by?: string;
    is_active?: boolean;
    is_deleted?: boolean;
    urgency?: string;
    stream?: Stream;
    stream_name?: string;
    task?: string;
    assigned_users?: Person[];
    reviewers?: Person[];
    reporters?: Person[];
    dueDate?: string;
    progress?: string;
    action?: string;
    steps?: string
}

interface TableProps<RowType extends { id: string }> { 
    headers: CollaborationTableHeader[];
    rows: TableRows[];  // rows are still TableRows
    options: { key: string; options: taskOptionInterface[] }[];
    hideEndDate?: boolean;
    onFilterChange?: (headerKey: string, value: string) => void;
    onEditIconClick?: (rowData: RowType) => void;
    onDeleteClick?: (item: RowType) => void;
    onFavoriteClick?: (item: RowType) => void;
    onPriorityChange?: (event: any, item: any) => void;
}

const SelectCustomTable = <RowType extends TableRows>({
    headers, 
    rows, 
    hideEndDate, 
    options, 
    onFilterChange,
    onEditIconClick,
    onDeleteClick,
    onFavoriteClick,
    onPriorityChange
}: TableProps<RowType>) => {

    const [favorites, setFavorites] = useState<{ [key: string]: boolean }>({});
        
    const handleDeleteClick = (item: RowType) => {
        if (onDeleteClick) {
            onDeleteClick(item); 
        }
    };
    const handleFavoriteClick = (item: RowType) => {
        setFavorites((prev) => ({
            ...prev,
            [item.id]: !prev[item.id], 
        }));

        if (onFavoriteClick) {
            onFavoriteClick(item); 
        }
            }
    return (
        <Box className={styles.box}>
            <TableContainer sx={{ background: '#F6FAFF', borderRadius: '0.75rem' }}>
                <Table sx={{ minWidth: 650 }} aria-label="custom table">
                    <TableHead className={styles.tableHeadRow}>
                        <TableRow>
                            <TableCell className={styles.customThead}>#</TableCell>
                            <TableCell className={styles.customThead}>Thread</TableCell>
                            {headers.map((header) => {
                                // Dynamically find options for the current header
                                const headerOptions = options.find((opt) => opt.key === header.key)?.options || [];

                                return (
                                    <TableCell key={header.key}>
                                        <Select
                                            placeholder={header.label}
                                            onChange={(event, value) => {
                                                if (onFilterChange) {
                                                    onFilterChange(header.key, value?.toString() || "");
                                                }
                                            }}
                                            indicator={
                                                <KeyboardArrowDown
                                                    sx={{
                                                        padding: '0',
                                                        color: '#636b74',
                                                    }}
                                                />
                                            }
                                            sx={{
                                                width: 'fit-content',
                                                border: 'none',
                                                padding: '0',
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                fontSize: '0.875rem',
                                                color: '#000000',
                                                fontWeight: '500',
                                                fontFamily: 'Inter',
                                                '& .MuiSelect-select': {
                                                    color: '#000000',
                                                },
                                                '& .MuiSelect-icon': {
                                                    color: '#636b74',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 'none',
                                                },
                                                [`& .${selectClasses.indicator}`]: {
                                                    transition: '0.2s',
                                                    [`&.${selectClasses.expanded}`]: {
                                                        transform: 'rotate(-180deg)',
                                                    },
                                                },
                                            }}

                                        >
                                            {headerOptions.length > 0 ? (
                                                headerOptions.map((option) => (
                                                    <Option key={option.id} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="no-options" disabled>
                                                    No Options
                                                </Option>
                                            )}
                                        </Select>
                                    </TableCell>
                                );
                            })}
                            <TableCell className={styles.customThead}>Status</TableCell>
                            <TableCell className={styles.customThead}>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((item, index) => (
                            <TableRow key={item.id} onClick={() => onEditIconClick?.(item as RowType)} style={{ cursor: 'pointer' }}>
                                <TableCell className={styles.bodySrText}>{index + 1}</TableCell>
                                <TableCell sx={{ cursor: 'pointer' }}>
                                    <a href={item.task_handle_links} target='blank'><img src="/assets/images/thread.png" alt="thread icon" /></a>
                                </TableCell>
                                <TableCell className={styles.bodyText}>{item.priority}</TableCell>
                                <TableCell className={styles.bodyText}>{item?.stream?.name && (item?.stream?.name)}</TableCell>
                                <TableCell className={styles.bodyText}>{`${item.steps} ${item.title}`}</TableCell>
                                {!hideEndDate && (
                                    <TableCell>
                                        {item.assigned_users && (
                                            <AvatarGroup max={3} className={styles.avtarAlign}>
                                                {item.assigned_users.map((person, idx) => (
                                                    <Tooltip title={person.name} arrow>
                                                        <Avatar key={idx} src={person.image} className={styles.avatar} />
                                                    </Tooltip>
                                                ))}
                                            </AvatarGroup>
                                        )}
                                    </TableCell>
                                )}
                                {!hideEndDate && (
                                    <TableCell>
                                        {item.reviewers && (
                                            <AvatarGroup max={3} className={styles.avtarAlign}>
                                                {item.reviewers.map((person, idx) => (
                                                    <Tooltip title={person.name} arrow>
                                                        <Avatar key={idx} src={person.image} className={styles.avatar} />
                                                    </Tooltip>
                                                ))}
                                            </AvatarGroup>
                                        )}
                                    </TableCell>
                                )}
                                {!hideEndDate && (
                                    <TableCell>
                                        {item.reporters && (
                                            <AvatarGroup max={3} className={styles.avtarAlign}>
                                                {item.reporters.map((person, idx) => (
                                                    <Tooltip title={person.name} arrow>
                                                        <Avatar key={idx} src={person.image} className={styles.avatar} />
                                                    </Tooltip>
                                                ))}
                                            </AvatarGroup>
                                        )}
                                    </TableCell>
                                )}
                                {!hideEndDate && (
                                    <TableCell className={styles.bodyText}>
                                        {item.end_date
                                            ? new Date(item.end_date).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: 'short',
                                            })
                                            : ''}
                                    </TableCell>
                                )}
                                <TableCell className={styles.bodyText}>{item.status}</TableCell>
                                <TableCell>
                                <Box className={styles.action}>
                                         <DriveFileRenameOutlineOutlinedIcon cursor="pointer"
                                        onClick={() => onEditIconClick?.(item as RowType)}
                                         />
                                         
                                         {(favorites[item.id] || (item?.top_priority && parseInt(item?.top_priority) > 0)) ? (
                                            <StarIcon
                                                sx={{ color: 'gold' }}
                                                cursor="pointer"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleFavoriteClick(item as RowType);
                                                }}
                                            />
                                        ) : (
                                            <StarBorderOutlinedIcon
                                                cursor="pointer"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleFavoriteClick(item as RowType);
                                                }}
                                            />
                                        )}
                                         <DeleteOutlineOutlinedIcon cursor="pointer"   
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleDeleteClick(item as RowType);
                                        }}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SelectCustomTable;